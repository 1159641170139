import { useEffect } from 'react';

const useDropdown = (buttonRef, dropdownRef, setToggle) => {
	useEffect(() => {
		const handleClickOutsideMenu = (event) => {
			const target = event.target;

			if (
				buttonRef.current &&
				dropdownRef.current &&
				!dropdownRef.current.contains(target) &&
				!buttonRef.current.contains(target)
			) {
				setToggle(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutsideMenu);
		return () => {
			// clean-up event listener
			document.removeEventListener('mousedown', handleClickOutsideMenu);
		};
	}, [buttonRef, dropdownRef, setToggle]);
};

export default useDropdown;
