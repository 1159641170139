import React from 'react';

import '../assets/styles/components/Checkbox.scss';

function Checkbox({ label, checked, disabled, id, value, onChange }) {
	const inputChecked = checked ? 'checked' : '';
	const inputDisabled = disabled ? 'disabled' : '';

	return (
		<div className='Checkbox'>
			<input
				type='checkbox'
				className='Checkbox__Input'
				checked={inputChecked}
				disabled={inputDisabled}
				onChange={onChange}
				value={value}
				id={id}
			/>
			<label htmlFor={id} className='Checkbox__Label'>
				{label}
			</label>
		</div>
	);
}

export default Checkbox;
