import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Store from './contexts/Store';
import App from './App';

const serviceWorkerConfig = {
	onUpdate: (registration) => {
		registration.unregister().then(() => {
			window.location.reload();
		});
	},
};

ReactDOM.render(
	<>
		<Store>
			<App />
		</Store>
	</>,
	document.getElementById('root')
);

serviceWorkerRegistration.register(serviceWorkerConfig);
