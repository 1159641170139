import React, { createContext } from 'react';
import Reducer from './Reducer';
import { useImmerReducer } from 'use-immer';
import { initialState } from './InitialState';

const Store = ({ children }) => {
	const [state, dispatch] = useImmerReducer(Reducer, initialState);
	const value = { state, dispatch };

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);
export default Store;
