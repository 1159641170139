import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../contexts/Store';
import SitePlan from './SitePlan';
import SiteSpin from '../pages/SiteSpin';
import BlockPlan from '../pages/Blockplan';

function Site() {
	let { id } = useParams();
	const { state, dispatch } = useContext(Context);
	const { plotContainers, siteplanToggle, globalSpinConfigData } = state;
	const [view, setView] = useState(null);

	const getCurrentSpinInfo = (thisId) => {
		if (globalSpinConfigData === false) return false;

		const index = globalSpinConfigData?.findIndex((e) => e.id === thisId);
		return index >= 0 ? globalSpinConfigData[index] : false;
	};

	useEffect(() => {
		getPlotContainerDataForId(plotContainers, id);
	}, [siteplanToggle, id]);

	const onPhaseChange = (_phase) => {
		dispatch({
			type: 'setSelectedPhase',
			data: Number(_phase),
		});
	};

	const getPlotContainerDataForId = (obj, thisTargetId) => {
		obj.every((plotContainerData) => {
			if (plotContainerData.id == thisTargetId || thisTargetId == undefined) {
				const containerType = plotContainerData.plotContainerType.name;

				if (containerType === 'Site') {
					const spinInfo = getCurrentSpinInfo(plotContainerData.id);

					const isTwelveFrame = Object.prototype.hasOwnProperty.call(spinInfo, 'isTwelveFrame')
						? spinInfo.isTwelveFrame
						: false;

					setView(
						siteplanToggle !== false && spinInfo !== false ? (
							<SiteSpin
								onPhaseChange={onPhaseChange}
								id={plotContainerData.id}
								selectedPhaseId={state.selectedPhase}
								isTwelveFrame={isTwelveFrame}
							/>
						) : (
							<SitePlan id={plotContainerData.id} displaySiteToggle={!!spinInfo} />
						)
					);
				} else if (containerType === 'Block' || containerType === 'Block Floor') {
					setView(<BlockPlan id={plotContainerData.id} />);
				}
				return false;
			}
			if (Object.prototype.hasOwnProperty.call(plotContainerData, 'plotContainers')) {
				getPlotContainerDataForId(plotContainerData.plotContainers, thisTargetId);
			}
			return true;
		});
	};

	return <>{view}</>;
}

export default Site;
