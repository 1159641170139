import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Context } from '../contexts/Store';
import { priceFormatter, dynamicSort, getStatus, findFloorplans } from '../helpers/utilities';

import AvailabilityBadge from './AvailabilityBadge';
import FavButton from './FavButton';
import CompareRow from './CompareRow';
import Locator from './Locator';

import '../assets/styles/components/PlotDetailCard.scss';
import ArrowIcon from './ArrowIcon';

import ImageZoomButton from './ImageZoomButton';
import { EqualHeightContext } from 'react-equal-height';
import Dims from './Dims';

function PlotDetailCard({ item }) {
	const { state } = useContext(Context);

	let status = getStatus(state.plotStatuses, 'id', item.plotStatusId);

	const isApartment = item.floorData?.floorName;
	const apartmentBlock = item.floorData?.blockId;

	let images = item.plotType.assets.slice().sort(dynamicSort('order'));
	let image = images[0];
	let floorplans = findFloorplans(images);

	// If floorplans in plot specific assets, use them instead
	if (item.plotSpecificAssets) {
		const plotSpecificFloorplans = findFloorplans(item.plotSpecificAssets);

		if (plotSpecificFloorplans.length) {
			floorplans = plotSpecificFloorplans;
		}
	}

	const { setForceUpdate } = useContext(EqualHeightContext);
	const handleImage = () => setForceUpdate((value) => !value);

	const LoadImage = ({ src, alt }) => {
		return <img src={src} onLoad={handleImage} alt={alt} />;
	};

	return (
		<section className='PlotDetailCard'>
			<div className='PlotDetailCard__Header'>
				<h2 className='PlotDetailCard__Number'>
					<Link to={`/homes/${item.id}`}>Plot {item.plotNumber}</Link>
				</h2>
				<div className='PlotDetailCard__Actions'>
					<FavButton plotID={item.id} />
					<Link className='HomeLink' to={`/homes/${item.id}`}>
						<ArrowIcon />
					</Link>
				</div>
			</div>

			<CompareRow name='Image' label={false}>
				{image && (
					<div className='Thumbnail'>
						<LoadImage src={image.fileUrl} alt={image.name} />
					</div>
				)}
			</CompareRow>

			<CompareRow name='Price'>
				{status.name === 'Available' ? priceFormatter(item.price) : null}
				{status.name === 'Unreleased' ? '£POA' : null}
			</CompareRow>

			<CompareRow name='Status'>
				<AvailabilityBadge variant='secondary' status={status} />
			</CompareRow>

			<CompareRow name='Bedrooms'>{item.plotType.numberOfBeds}</CompareRow>

			<CompareRow name='Type'>{item.name}</CompareRow>

			<CompareRow name='Floorplans'>
				{floorplans.map((image) => {
					return (
						<div className='Thumbnail' key={image.assetId}>
							<LoadImage src={image.fileUrl} alt={image.name} />
							<div className='Thumbnail__Caption'>{image.caption}</div>
							<div className='Thumbnail__Zoom'>
								<ImageZoomButton src={image.fileUrl} caption={image.caption} gallery={item.id} />
							</div>
						</div>
					);
				})}
			</CompareRow>

			<CompareRow name='Dimensions'>
				<Dims data={item.plotType} compareCard={true} />
			</CompareRow>

			<CompareRow name='Site Location'>
				<Locator
					containerId={isApartment && apartmentBlock !== null ? apartmentBlock : item.id}
					onChange={handleImage}
				/>
			</CompareRow>

			{isApartment ? (
				<CompareRow name={item.floorData?.floorName}>
					<Locator containerId={item.id} onChange={handleImage} />
				</CompareRow>
			) : (
				// Empty row required for equal row numbers across columns
				<CompareRow name='Floor Location' label={false}></CompareRow>
			)}
		</section>
	);
}

export default PlotDetailCard;
