import { useMediaQuery } from 'react-responsive';

const useCheckDevice = () => {
	const isMobile = useMediaQuery({
		query: '(max-width: 740px)',
	});
	const isTablet = useMediaQuery({
		query: '(max-width: 980px)',
	});

	const isDesktop = !isTablet && !isMobile;

	return { isMobile, isTablet, isDesktop };
};

export default useCheckDevice;
