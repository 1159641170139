import React, { useContext } from 'react';
import useCheckDevice from '../hooks/useCheckDevice';
import { Context } from '../contexts/Store';

function PlotMarkerBP(props) {
	const { state, dispatch } = useContext(Context);
	const { selectedPlotId, isPreviewPanelOpen } = state;
	const { posX, posY, markerWidth, markerHeight, plotColour, plotId, plotNum, isPanning } = props;
	const { isMobile } = useCheckDevice();

	const isFav = state.favPlots
		? state.favPlots.includes(plotId)
			? 'plot-marker-bp--fav'
			: ''
		: null;

	let divStyle = {
		top: parseInt(posY - markerHeight / 2) + 'px',
		left: parseInt(posX - markerHeight / 2) + 'px',
		width: parseInt(markerWidth * 2) + 'px',
		height: parseInt(markerHeight * 2) + 'px',
		backgroundColor: plotColour,
	};

	divStyle.transform = 'scale(0.5)';

	const textStyle = {
		fontSize:
			plotNum.length > 3 ? parseInt(markerWidth * 0.5) + 'px' : parseInt(markerWidth * 0.8) + 'px',

		paddingTop: isMobile ? '0.1em' : '0',
	};

	const PlotMarkerClicked = () => {
		if (isPanning) return;
		dispatch({
			type: 'showPlotPreview',
			data: { plotid: plotId, markerId: plotNum },
		});
	};

	const getSelectedPlotId =
		selectedPlotId === plotId && isPreviewPanelOpen ? 'plot-marker-bp--active' : '';

	return (
		<div
			key={plotNum}
			id={plotNum}
			className={`plot-marker-bp ${getSelectedPlotId} ${isFav}`}
			onClick={PlotMarkerClicked}
			style={divStyle}
		>
			<div className='plot-marker-bp__text' style={textStyle}>
				{plotNum}
			</div>
		</div>
	);
}

export default PlotMarkerBP;
