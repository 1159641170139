import { fromPrice, toPrice, getStatusID, getStatus } from '../helpers/utilities';
import { sortHomes } from '../helpers/sortHomes';
import plotsToTypes from '../helpers/plotsToTypes';

export function setupFilters(state, dispatch) {
	let prices = [];
	let bedrooms = [];
	let houseTypes = [];
	let floors = [];
	let buildings = [];
	let allCustomFilters = [];
	let filtersList = [];
	let phases = [];

	if (state.plots && state.plotStatuses) {
		// Loop through available homes to retrieve applicable filter values
		let homes = state.plots.filter((el) => {
			if (el.plotStatusId !== getStatusID(state.plotStatuses, 'Hidden')) {
				//Initial setup of filter options based on available homes
				if (el.plotStatusId === getStatusID(state.plotStatuses, 'Available')) {
					bedrooms.push(el.plotType.numberOfBeds);
					houseTypes.push(el.plotType.name);

					//price range is based only on available homes
					prices.push(el.price);

					if (el.floorData) {
						floors.push(el.floorData.floorName);
						buildings.push(el.floorData.blockName);
					}

					if (el.customFields) {
						el.customFields.filter((cf) => {
							if (cf && cf.dataFilterType !== null) {
								allCustomFilters.push(cf);
								return cf;
							} else {
								return null;
							}
						});
					}

					return el;
				}

				if (el.developmentPhase) phases.push(el.developmentPhase.name);
			}
			return null;
		});

		// Find the min and max price of all homes
		let maxPrice = toPrice(prices);
		let minPrice = fromPrice(prices);

		// Add values to price filter
		if (!isFinite(minPrice) && !isFinite(maxPrice)) {
			// No price data therefore don't add the filter
		} else if (minPrice === maxPrice) {
			// No price data therefore don't add the filter
		} else {
			filtersList.push({
				id: 1,
				name: 'price',
				displayName: 'Price Range',
				type: 'range',
				values: [minPrice, maxPrice],
				selectedValues: [minPrice, maxPrice],
			});

			let newRangeLabels = [...state.rangeLabels];
			newRangeLabels[1] = [minPrice, maxPrice];

			dispatch({
				type: 'setRangeLabels',
				data: newRangeLabels,
			});
		}

		//add options

		bedroomsFilterOptions(bedrooms, filtersList);
		houseTypeFilterOptions(houseTypes, filtersList);
		buildingsFilterOptions(buildings, filtersList);
		floorsFilterOptions(floors, filtersList);
		phasesFilterOptions(phases, filtersList);
		customFieldOptions(allCustomFilters, filtersList);

		// Add listing statuses
		if (state.plotStatuses) {
			let plotStatusValues = [];
			let excludedStatuses = ['Hidden'];

			state.plotStatuses.map((item) => {
				if (!excludedStatuses.includes(item.name)) {
					const isChecked = item.name === 'Available' ? true : false;
					plotStatusValues.push({
						label: item.name,
						value: item.name,
						isChecked: isChecked,
						color: item.color,
					});
				}
			});

			// Sort values alphabetically
			plotStatusValues
				.sort((a, b) => {
					if (!a.label) return;

					let aVal = a.label.toLowerCase(),
						bVal = b.label.toLowerCase();

					return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
				})

				.sort((a, b) => {
					var textA = a.label.toUpperCase();
					var textB = b.label.toUpperCase();
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				});

			filtersList.push({
				id: 6,
				name: 'plotStatus',
				displayName: 'Status',
				type: 'checkbox',
				values: plotStatusValues,
				active: true,
			});
		}

		// Filter out excluded filters
		if (state.excludedFilters) {
			filtersList = filtersList.filter((filter) => !state.excludedFilters.includes(filter.name));
		}

		// Set available filters
		dispatch({
			type: 'setFilters',
			data: filtersList,
		});

		// Set available homes
		dispatch({
			type: 'setAvailableHomes',
			data: homes,
		});

		dispatch({
			type: 'setFilteredHomes',
			data: homes,
		});
	}
}

export function updateFilterOptions(state, dispatch, newFilters) {
	//get all the current status options
	let statusList = [];

	if (newFilters.length > 0) {
		//get the plotStatus filter from the filters
		let statusFilter = newFilters.filter((filter) => filter.name === 'plotStatus')[0];

		//check that the plotStatus filter is active
		if (statusFilter.active) {
			//return the currently selected statuses
			statusFilter.values.filter((value) => {
				if (value.isChecked === true) {
					statusList.push(getStatusID(state.plotStatuses, value.label));
					return value.label;
				}
			});

			//if plotStatus is inactive return all statuses
		} else {
			statusFilter.values.filter((value) => {
				statusList.push(getStatusID(state.plotStatuses, value.label));
				return value.label;
			});
		}
	}

	//loop homes with status applied so we can get updated list of options
	let bedrooms = [];
	let houseTypes = [];
	let floors = [];
	let buildings = [];
	let allCustomFilters = [];
	let phases = [];

	if (state.plots && state.plotStatuses) {
		// Loop through available homes to retrieve applicable filter values
		state.plots.filter((el) => {
			if (el.plotStatusId !== getStatusID(state.plotStatuses, 'Hidden')) {
				//check whether this plot's status matches a selected status filter value
				if (statusList.includes(el.plotStatusId)) {
					// do not push plot's price into price range filter values
					// as they are set on setupFilters and must only include available plots
					// prices.push(el.price)

					bedrooms.push(el.plotType.numberOfBeds);
					houseTypes.push(el.plotType.name);

					if (el.developmentPhase) phases.push(el.developmentPhase.name);

					if (el.floorData) {
						floors.push(el.floorData.floorName);
						buildings.push(el.floorData.blockName);
					}

					if (el.customFields) {
						el.customFields.filter((cf) => {
							if (cf && cf.dataFilterType !== null) {
								allCustomFilters.push(cf);
								return cf;
							} else {
								return null;
							}
						});
					}

					return el;
				}
			}
			return null;
		});
	}

	//loop the filters

	let updatedFilters = JSON.parse(JSON.stringify(newFilters));

	updatedFilters.forEach((filter, index) => {
		if (filter.name === 'price' || filter.name === 'plotStatus') {
			// do nothing
		} else if (filter.name === 'bedrooms') {
			//pass the index and old options to update
			bedroomsFilterOptions(bedrooms, updatedFilters, index, filter);
		} else if (filter.name === 'houseTypes') {
			houseTypeFilterOptions(houseTypes, updatedFilters, index, filter);
		} else if (filter.name === 'floors') {
			floorsFilterOptions(floors, updatedFilters, index, filter);
		} else if (filter.name === 'buildings') {
			buildingsFilterOptions(buildings, updatedFilters, index, filter);
		} else if (filter.name === 'phases') {
			phasesFilterOptions(phases, updatedFilters, index, filter);
		} else {
			//assume custom
			updateCustomFieldOptions(allCustomFilters, updatedFilters, index, filter);
		}
	});

	dispatch({
		type: 'setFilters',
		data: updatedFilters,
	});
}

//Filter options

//Bedrooms
function bedroomsFilterOptions(
	newValues,
	filterListToUpdate,
	listIndex = false,
	existingFilterOptions = false
) {
	let uniqueValues = [...new Set(newValues)]; //get unique
	let orderedValues = uniqueValues.sort((a, b) => a - b);

	let newValuesList = [];

	orderedValues.forEach((value) => {
		let label = value === 1 ? `${value} bed` : `${value} beds`;
		newValuesList.push({ label: label, value: value });
	});

	let filterSetting = {
		id: 2,
		name: 'bedrooms',
		displayName: 'Number of beds',
		type: 'checkbox',
	};

	return filterOptions(
		filterSetting,
		newValuesList,
		filterListToUpdate,
		listIndex,
		existingFilterOptions
	);
}

//Housetypes
function houseTypeFilterOptions(
	newValues,
	filterListToUpdate,
	listIndex = false,
	existingFilterOptions = false
) {
	let uniqueValues = [...new Set(newValues)]; //get unique
	let orderedValues = uniqueValues.sort((a, b) => a - b);

	let newValuesList = [];

	orderedValues.forEach((value) => {
		newValuesList.push({ label: value, value: value });
	});

	let filterSetting = {
		id: 3,
		name: 'houseTypes',
		displayName: 'Property Type',
		type: 'checkbox',
	};

	return filterOptions(
		filterSetting,
		newValuesList,
		filterListToUpdate,
		listIndex,
		existingFilterOptions
	);
}

//Phases
function phasesFilterOptions(
	newValues,
	filterListToUpdate,
	listIndex = false,
	existingFilterOptions = false
) {
	let uniqueValues = [...new Set(newValues)]; //get unique
	let orderedValues = uniqueValues.sort((a, b) => a - b);

	let newValuesList = [];

	orderedValues.forEach((value) => {
		newValuesList.push({ label: value, value: value });
	});

	let filterSetting = {
		id: 7,
		name: 'phases',
		displayName: 'Phase',
		type: 'checkbox',
	};

	return filterOptions(
		filterSetting,
		newValuesList,
		filterListToUpdate,
		listIndex,
		existingFilterOptions
	);
}

//Buildings
function buildingsFilterOptions(
	newValues,
	filterListToUpdate,
	listIndex = false,
	existingFilterOptions = false
) {
	let uniqueValues = [...new Set(newValues)]; //get unique
	let orderedValues = uniqueValues.sort((a, b) => a - b);

	let newValuesList = [];

	orderedValues.forEach((value) => {
		newValuesList.push({ label: value, value: value });
	});

	let filterSetting = {
		id: 4,
		name: 'buildings',
		displayName: 'Building',
		type: 'checkbox',
	};

	return filterOptions(
		filterSetting,
		newValuesList,
		filterListToUpdate,
		listIndex,
		existingFilterOptions
	);
}

//Floors
function floorsFilterOptions(
	newValues,
	filterListToUpdate,
	listIndex = false,
	existingFilterOptions = false
) {
	let uniqueValues = [...new Set(newValues)]; //get unique
	let orderedValues = uniqueValues.sort((a, b) => a - b);

	let newValuesList = [];

	orderedValues.forEach((value) => {
		newValuesList.push({ label: value, value: value });
	});

	let filterSetting = {
		id: 5,
		name: 'floors',
		displayName: 'Floor',
		type: 'checkbox',
	};

	return filterOptions(
		filterSetting,
		newValuesList,
		filterListToUpdate,
		listIndex,
		existingFilterOptions
	);
}

function filterOptions(
	filterSetting,
	newValuesList,
	filterListToUpdate,
	listIndex = false,
	existingFilterOptions = false
) {
	let newValues = [];
	let filterIsActive = false;

	newValuesList.forEach(({ label, value }) => {
		let matchedExisting = false;

		if (existingFilterOptions) {
			// check if we have old filter options to update.

			existingFilterOptions.values.forEach((oldVal) => {
				if (oldVal.value === value) {
					newValues.push(oldVal);
					if (oldVal.isChecked) {
						filterIsActive = true;
					}
					matchedExisting = true;
					return;
				}
			});
		}

		if (matchedExisting === false) {
			newValues.push({
				label: label,
				value: value,
				isChecked: false,
			});
		}
	});

	filterSetting.active = filterIsActive;
	filterSetting.values = newValues;

	if (listIndex !== false) {
		filterListToUpdate.splice(listIndex, 1, filterSetting);
	} else {
		filterListToUpdate.push(filterSetting);
	}

	return true;
}

function customFieldOptions(allCustomFilters, filtersList) {
	let customFiltersIDs = [...new Set(allCustomFilters.flatMap(({ id }) => id))].sort();

	customFiltersIDs.forEach((id) => {
		let field = allCustomFilters.filter((cf) => {
			if (cf.id === id) {
				return cf;
			} else {
				return null;
			}
		});

		// Get unique field values
		let uniqueValues = [...new Set(field.flatMap(({ fieldValue }) => fieldValue))].sort();

		// Add checkbox fields
		if (field[0].dataFilterType === 2) {
			let values = [];
			uniqueValues.forEach((value) => {
				values.push({
					label: value,
					value: value,
					isChecked: false,
				});
			});

			filtersList.push({
				id: id,
				name: field[0].displayName,
				displayName: field[0].displayName,
				type: 'checkbox',
				values: values,
			});

			// Add boolean fields
		} else if (field[0].dataFilterType === 3) {
			let values = [];
			uniqueValues.forEach((value) => {
				if (value === 'true' || value === 'True') {
					values.push({
						label: 'Yes',
						value: value,
						isChecked: false,
					});
				}
			});

			filtersList.push({
				id: id,
				name: field[0].displayName,
				displayName: field[0].displayName,
				type: 'checkbox',
				values: values,
			});
		}
	});
}

function updateCustomFieldOptions(allCustomFilters, filtersList, listIndex, existingFilterOptions) {
	let customFiltersIDs = [...new Set(allCustomFilters.flatMap(({ id }) => id))]
		.sort()
		.filter((id) => id === existingFilterOptions.id);

	customFiltersIDs.forEach((id) => {
		let field = allCustomFilters.filter((cf) => {
			if (cf.id === id) {
				return cf;
			} else {
				return null;
			}
		});

		// Get unique field values
		let uniqueValues = [...new Set(field.flatMap(({ fieldValue }) => fieldValue))].sort();

		if (field[0].dataFilterType === 2) {
			let newValues = [];
			let filterIsActive = false;
			uniqueValues.forEach((value) => {
				let matchedExisting = false;
				if (existingFilterOptions) {
					// check if we have old filter options to update.
					existingFilterOptions.values.forEach((oldVal) => {
						if (oldVal.value === value) {
							newValues.push(oldVal);
							if (oldVal.isChecked) {
								filterIsActive = true;
							}
							matchedExisting = true;
							return;
						}
					});
				}

				if (matchedExisting === false) {
					newValues.push({
						label: value,
						value: value,
						isChecked: false,
					});
				}
			});

			filtersList.splice(listIndex, 1, {
				id: id,
				name: field[0].displayName,
				displayName: field[0].displayName,
				type: 'checkbox',
				values: newValues,
				active: filterIsActive,
			});
		} else if (field[0].dataFilterType === 3) {
			if (existingFilterOptions.active) {
				filtersList.splice(listIndex, 1, {
					id: id,
					name: field[0].displayName,
					displayName: field[0].displayName,
					type: 'checkbox',
					values: existingFilterOptions.values,
					active: true,
				});
			} else {
				let values = [];
				uniqueValues.forEach((value) => {
					if (value === 'true' || value === 'True') {
						values.push({
							label: 'Yes',
							value: value,
							isChecked: false,
						});
					}
				});

				filtersList.splice(listIndex, 1, {
					id: id,
					name: field[0].displayName,
					displayName: field[0].displayName,
					type: 'checkbox',
					values: values,
					active: false,
				});
			}
		}
	});
}

export function runFilters(state, dispatch) {
	let filteredResults = state.plots.filter((el) => {
		let active = true; // default to show

		if (state.filters) {
			state.filters.forEach((filter) => {
				if (filter.type === 'range') {
					//Price range
					let itemValue = parseInt(el[filter.name]);

					if (filter.selectedValues[0] <= itemValue && filter.selectedValues[1] >= itemValue) {
						active = true;
					} else if (el.plotStatusId !== getStatusID(state.plotStatuses, 'Available')) {
						//only apply this filter to available homes everything else is let through
						active = true;
					} else {
						active = false;
					}
				} else if (filter.type === 'checkbox') {
					//checkbox

					//get the filter values from filter into an array
					let filterValues = [];
					filter.values.forEach((val) => {
						if (val.isChecked) {
							filterValues.push(val.value);
						}
					});

					if (filter.name === 'bedrooms') {
						if (filterValues && filterValues.length) {
							if (!filterValues.includes(el.plotType.numberOfBeds)) {
								active = false;
							}
						}
					} else if (filter.name === 'floors') {
						if (filterValues && filterValues.length) {
							if (!filterValues.includes(el.floorData.floorName)) {
								active = false;
							}
						}
					} else if (filter.name === 'phases') {
						if (filterValues && filterValues.length) {
							if (el.developmentPhase) {
								if (!filterValues.includes(el.developmentPhase.name)) {
									active = false;
								}
							}
						}
					} else if (filter.name === 'buildings') {
						if (filterValues && filterValues.length) {
							if (!filterValues.includes(el.floorData.blockName)) {
								active = false;
							}
						}
					} else if (filter.name === 'houseTypes') {
						if (filterValues && filterValues.length) {
							if (!filterValues.includes(el.plotType.name)) {
								active = false;
							}
						}
					} else if (filter.name === 'plotStatus') {
						if (filterValues && filterValues.length) {
							const name = getStatus(state.plotStatuses, 'id', el.plotStatusId).name;
							if (!filterValues.includes(name)) {
								active = false;
							}
						}
					} else {
						//custom filters

						if (filterValues && filterValues.length) {
							//get this custom field value
							let customFieldValue = null;
							if (el.customFields && el.customFields.length) {
								el.customFields.forEach((customField) => {
									if (customField.id === filter.id) {
										customFieldValue = customField.fieldValue;
									}
								});
							}

							if (filterValues && filterValues.length) {
								if (!filterValues.includes(customFieldValue)) {
									// found element
									active = false;
								}
							}
						}
					}
				}
			});
		}
		return active;
	});

	filteredResults = sortHomes(filteredResults, state.homesSortKey, state.homesSortOrder);

	dispatch({
		type: 'setFilteredHomes',
		data: filteredResults,
	});

	dispatch({
		type: 'setTypes',
		data: plotsToTypes(filteredResults, state.plotStatuses),
	});
}

export function resetFilter(state, dispatch, filterIndex) {
	let newFilters = JSON.parse(JSON.stringify(state.filters));

	// Reset range values
	if (state.filters[filterIndex].type === 'range') {
		newFilters[filterIndex].selectedValues = newFilters[filterIndex].values;

		// Remove selected checkboxes
	} else if (state.filters[filterIndex].type === 'checkbox') {
		newFilters[filterIndex].values.map((value) => {
			value.isChecked = false;
			return value;
		});
	}

	// Remove active state from filter
	newFilters[filterIndex].active = false;

	dispatch({
		type: 'setFilters',
		data: newFilters,
	});
}

export function resetAllFilters(state, dispatch) {
	let newFilters = JSON.parse(JSON.stringify(state.filters));

	// Reset all values
	newFilters.map((filter, index) => {
		if (state.filters[index]) {
			// Reset the 'Status' filter to Available
			if (state.filters[index].name === 'plotStatus') {
				newFilters[index].values.map((value) => {
					value.isChecked = value.label === 'Available';
					return value;
				});

				// Reset the Price filter
			} else if (state.filters[index].type === 'range') {
				newFilters[index].selectedValues = newFilters[index].values;

				// Reset checkboxes
			} else if (state.filters[index].type === 'checkbox') {
				newFilters[index].values.map((value) => {
					value.isChecked = false;
					return value;
				});
			}

			// Remove active state from filter if this isn't Status
			newFilters[index].active = state.filters[index].name === 'plotStatus';
		}
	});

	dispatch({
		type: 'setFilters',
		data: newFilters,
	});

	updateFilterOptions(state, dispatch, newFilters);
}
