import React, { useLayoutEffect } from 'react';

import ReactDom from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

import '../assets/styles/components/Modal.scss';
import { ReactComponent as CloseIcon } from '../assets/img/close-cross-icon.svg';

/**
 * Modal
 * SitePlanCardModal
 */
function Modal({ children, onClose, isLarge }) {
	useLockBodyScroll();

	return ReactDom.createPortal(
		<div className='Modal'>
			<div className='Modal__Overlay' onClick={onClose} />

			<button className='Modal__CloseButton' onClick={onClose}>
				<CloseIcon />
			</button>

			<AnimatePresence>
				<motion.div
					className={`Modal__Container ${isLarge === true ? 'large' : ''}`}
					layout
					initial={{ opacity: 0, y: 50, scale: 0.3 }}
					animate={{ opacity: 1, y: 0, scale: 1 }}
					exit={{ opacity: 0, y: 20, scale: 0.5 }}
				>
					{children}
				</motion.div>
			</AnimatePresence>
		</div>,
		document.body
	);
}

//SitePlanCardModal
export const SitePlanCardModal = ({ children, onClose }) => {
	return ReactDom.createPortal(
		<div className='Modal'>
			<div className='Modal__Overlay' onClick={onClose} />

			<AnimatePresence>
				<motion.div
					layout
					initial={{ opacity: 0, y: 50, scale: 0.3 }}
					animate={{ opacity: 1, y: 0, scale: 1 }}
					exit={{ opacity: 0, y: 20, scale: 0.5 }}
				>
					{children}
				</motion.div>
			</AnimatePresence>
		</div>,
		document.body
	);
};

function useLockBodyScroll() {
	useLayoutEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow;
		document.body.style.overflow = 'hidden';
		return () => (document.body.style.overflow = originalStyle);
	}, []);
}

export default Modal;
