import React, { useContext } from 'react';
import { Context } from '../contexts/Store';
import useCheckDevice from '../hooks/useCheckDevice';
import Meta from './Meta';
import PlotLink from './PlotLink';
import { TotalArea, enableButton } from '../components/TotalArea';

import '../assets/styles/components/MiniType.scss';

function MiniType({ type }) {
	const { state } = useContext(Context);
	const { isMobile } = useCheckDevice();

	return (
		<section className='MiniType'>
			<div className='MiniType__Header'>
				<h3 className='MiniType__Heading'>{type.name}</h3>
				<Meta
					display={isMobile ? 'list' : 'row'}
					beds={type.numberOfBeds}
					area={TotalArea(type)}
					enableButton={enableButton(type)}
				/>
			</div>
			<div className='MiniType__Plots'>
				{state.filteredHomes.map((plot, i) => {
					if (plot.plotType.id === type.id) {
						return <PlotLink plot={plot} key={i} />;
					}
				})}
			</div>
		</section>
	);
}

export default MiniType;
