import React, { useState } from 'react';
import DimsCards from './DimsCards';
import FloorSelector from './FloorSelector';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import MapControl from './MapControl';
import Modal from './Modal';

import '../assets/styles/components/PlotModal.scss';

function PlotModal({ floorplans, onClose, plotType }) {
	const [activeFloorplan, setActiveFloorplan] = useState(0);
	const [floorPlanPan, setFloorPlanPan] = useState(false);

	return (
		<Modal onClose={onClose}>
			<main className='PlotModal'>
				<div className='PlotModal__Floorplans'>
					<div className='PlotModal__Floorplans__Floorplan'>
						<TransformWrapper
							initialScale={1}
							centerOnInit
							maxScale={4}
							minScale={1}
							wheel={{
								disabled: true,
							}}
							panning={{
								disabled: !floorPlanPan && window.innerWidth <= 740 ? true : false,
							}}
						>
							{({ zoomIn, zoomOut, centerView, state }) => {
								return (
									<React.Fragment>
										<div className='PlotModal__Floorplans__PinchZoomControls'>
											<MapControl
												variant='zoom-in'
												onClick={() => {
													zoomIn();
													if (!floorPlanPan) {
														setFloorPlanPan(true);
													}
												}}
												title='Zoom in'
												disabled={state.scale === 4}
											/>
											<MapControl
												variant='zoom-out'
												onClick={() => {
													zoomOut();
													if (state.scale - 0.65 <= 1 && floorPlanPan) {
														setFloorPlanPan(false);
													}
												}}
												title='Zoom out'
												disabled={state.scale <= 1}
											/>
											<MapControl
												variant='center-map'
												onClick={() => {
													centerView(1, 100, 'easeOut');
													if (floorPlanPan) {
														setFloorPlanPan(false);
													}
												}}
												title='Center map'
											/>
										</div>
										<div className='PlotModal__Floorplans__Floorplan__Caption'>
											{floorplans[activeFloorplan].caption}
										</div>
										<TransformComponent
											wrapperStyle={{
												width: '100%',
												height: '100%',
												borderRadius: 'calc(var(--theme-border-radius) * 2)',
												webkitMaskImage: '-webkit-radial-gradient(white, black)',
											}}
										>
											<img
												className='PlotModal__Floorplans__Floorplan__Image Plot__Floorplans__Floorplan__Image--nopan'
												src={floorplans[activeFloorplan].fileUrl}
												alt={floorplans[activeFloorplan].name}
												onLoad={() => centerView(1, 100, 'easeOut')}
											/>
										</TransformComponent>
									</React.Fragment>
								);
							}}
						</TransformWrapper>
					</div>
					<div className='PlotModal__Floorplans__Controls'>
						<DimsCards data={plotType} />
						{floorplans.length > 1 && (
							<FloorSelector
								floors={floorplans}
								activeFloor={activeFloorplan}
								setActiveFloor={setActiveFloorplan}
							/>
						)}
					</div>
				</div>
			</main>
		</Modal>
	);
}

export default PlotModal;
