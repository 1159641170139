import { useContext } from 'react';
import { Context } from '../contexts/Store';

import ToggleButton from './ToggleButton';

function CompareToggle() {
	const { state, dispatch } = useContext(Context);

	const handleClick = () => {
		dispatch({ type: 'resetComparePlots' });
		dispatch({
			type: 'setIsCompareMode',
			data: !state.isCompareMode,
		});
	};

	return (
		<div className='CompareToggle'>
			<ToggleButton icon='compare' active={state.isCompareMode} size='sm' onClick={handleClick}>
				Compare
			</ToggleButton>
		</div>
	);
}

export default CompareToggle;
