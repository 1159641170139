import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../contexts/Store';
import { filteredCountByType } from '../helpers/utilities';
import { Helmet } from 'react-helmet';
import ActiveFilters from '../components/ActiveFilters';
import Type from '../components/Type';
import PlotCard from '../components/PlotCard';
import Alert from '../components/Alert';
import SubMenu, { SubMenuViewTabs } from '../components/SubMenu';
import CompareToggle from '../components/CompareToggle';
import CompareComboBar from '../components/CompareComboBar';
import PlotRow from '../components/PlotRow';
import SortButton from '../components/SortButton';
import SortByDropdown from '../components/SortByDropdown';
// import { sortHomes } from '../helpers/sortHomes';

import '../assets/styles/pages/Homes.scss';
import '../assets/styles/pages/Types.scss';

function Homes() {
	const { state, dispatch } = useContext(Context);
	const [activeView, setActiveView] = useState(state.homesDefaultView);

	const views = [
		{
			label: 'Types',
			slug: 'types',
		},
		{
			label: 'Homes',
			slug: 'homes',
		},
	];

	useEffect(() => {
		if (!state.homesSortKey) {
			dispatch({
				type: 'setHomesSortKey',
				data: 'plotNumber',
			});
		}
	}, [state.filteredHomes]);

	// const changePhase = (phaseId) => {
	// 	const _selectedDevelopment = state.developmentPhases.filter(
	// 		(e) => e.plotContainerId === Number(phaseId)
	// 	);
	// 	const _filteredHomes = state.filteredHomes.filter(
	// 		(e) => e.developmentPhaseId === _selectedDevelopment[0].id
	// 	);

	// 	if (_filteredHomes && _filteredHomes.length) {
	// 		const sortedHomes = sortHomes(_filteredHomes, state.homesSortKey, state.homesSortOrder);
	// 		dispatch({
	// 			type: 'setFilteredHomes',
	// 			data: sortedHomes,
	// 		});
	// 	}
	// };

	return (
		<div className='Homes'>
			<Helmet>
				<title>{`Homes - ${state.devName.name}`}</title>
			</Helmet>

			<SubMenu>
				{!state.isMobileDevice && !state.isTabletDevice && activeView === 'homes' && (
					<SortByDropdown placement='above' />
				)}
				<CompareToggle />
				<SubMenuViewTabs
					views={views}
					activeView={activeView}
					setActiveView={(slug) => setActiveView(slug)}
				></SubMenuViewTabs>
			</SubMenu>

			<div className='container'>
				<div className='Homes__PageHeader'>
					<ActiveFilters />

					{(state.isMobileDevice || state.isTabletDevice || state.developmentType === 'homes') &&
						activeView === 'homes' && (
							<div className='Homes__Sort'>
								<SortByDropdown />
							</div>
						)}
				</div>

				{state.isCompareMode && <CompareComboBar />}

				{!state.filteredHomes.length && (
					<Alert>No homes matched your criteria. Please try different options.</Alert>
				)}

				{activeView === 'types' && (
					<div className='Types'>
						{state.types &&
							state.types.map((item, i) => {
								if (filteredCountByType(state.filteredHomes, item) > 0) {
									return <Type item={item} key={i} />;
								}
							})}
					</div>
				)}

				{
					// If this is the homes view and there are homes available
					// On small devices or homes-only developments display a grid
					activeView === 'homes' &&
					state.filteredHomes.length !== 0 &&
					(state.isMobileDevice || state.isTabletDevice || state.developmentType === 'homes') ? (
						<div className='Plot__Grid'>
							{state.filteredHomes.map((item, i) => (
								<PlotCard item={item} key={i} />
							))}
						</div>
					) : null
				}

				{/* <div className='sub-menu'>
					<div className='container'>
						{state.spinConfigData && state.spinConfigData.length > 1 ? (
							<SubMenu>
								<SubMenuSelect
									list={state.plotContainers}
									value={state.phaseId}
									onChange={(e) => changePhase(e.target.value)}
									type='phase'
								></SubMenuSelect>
							</SubMenu>
						) : null}
					</div>
				</div> */}

				{
					// For mixed/apartments developments display a table view on desktop only
					activeView === 'homes' &&
					state.filteredHomes.length !== 0 &&
					!state.isMobileDevice &&
					!state.isTabletDevice &&
					state.developmentType !== 'homes' ? (
						<div className='Homes__Table'>
							<div className='Homes__TableHeader'>
								<div className='Homes__TableHeader__Col'></div>
								<div className='Homes__TableHeader__Col'>
									<SortButton sortKey='plotNumber'>Plot</SortButton>
								</div>
								<div className='Homes__TableHeader__Col'>
									{state.developmentType === 'apartments' ? (
										<SortButton sortKey='floor'>Floor</SortButton>
									) : (
										<SortButton sortKey='name'>Type</SortButton>
									)}
								</div>
								<div className='Homes__TableHeader__Col'>
									<SortButton sortKey='description'>Bedrooms</SortButton>
								</div>
								<div className='Homes__TableHeader__Col'>
									<SortButton sortKey='price'>Price</SortButton>
								</div>
								<div className='Homes__TableHeader__Col'>
									<SortButton sortKey='plotStatusId'>Status</SortButton>
								</div>
								{state.developmentPhases && state.developmentPhases.length > 1 ? (
									<div className='Homes__TableHeader__Col'>
										<SortButton sortKey='phaseId'>Phase</SortButton>
									</div>
								) : null}
								<div className='Homes__TableHeader__Col'></div>
							</div>
							{state.filteredHomes.map((item, i) => (
								<PlotRow plot={item} key={i} />
							))}
						</div>
					) : null
				}
			</div>
		</div>
	);
}

export default Homes;
