import React, { useContext } from 'react';

import { Context } from '../contexts/Store';
import { priceFormatter, getStatus } from '../helpers/utilities';
import { TotalArea, enableButton } from '../components/TotalArea';

import AvailabilityBadge from '../components/AvailabilityBadge';
import Meta from '../components/Meta';

import '../assets/styles/components/PlotHeader.scss';

function PlotCard({ plot }) {
	const { state } = useContext(Context);
	let status = getStatus(state.plotStatuses, 'id', plot.plotStatusId);

	return (
		<header className='PlotHeader'>
			<div className='PlotHeader__Intro'>
				<h1 className='PlotHeader__Name'>Plot {plot.plotNumber}</h1>
				<h2 className='PlotHeader__Type'>{plot.name}</h2>
				<p className='PlotHeader__Price'>
					{status.name === 'Available' ? priceFormatter(plot.price) : '£POA'}
				</p>
				<div className='PlotHeader__Availability'>
					<AvailabilityBadge variant='secondary' status={status} />
				</div>
			</div>
			<div className='PlotHeader__Meta'>
				<Meta
					beds={plot.plotType.numberOfBeds}
					area={TotalArea(plot.plotType)}
					floorData={plot.floorData}
					enableButton={enableButton(plot.plotType)}
				/>
			</div>
		</header>
	);
}

export default PlotCard;
