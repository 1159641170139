import { React, useState, useContext, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Context } from '../contexts/Store';
import Fancybox from '../components/Fancybox';
import { SuspenseImg } from '../components/SuspenseImage';
import SubMenu, { SubMenuSelect } from '../components/SubMenu';

import '../assets/styles/pages/Gallery.scss';
import { ReactComponent as PlayIcon } from '../assets/img/play-circle-duotone.svg';

function InteriorTours() {
	const { state } = useContext(Context);
	const { galleryData } = state;

	const [galleryKey, setGalleryKey] = useState(0);

	function changeGallery(key) {
		setGalleryKey(key);
	}

	function LoadingImages() {
		return (
			<div className='Gallery__Grid'>
				{galleryData && galleryData[galleryKey].assets
					? galleryData[galleryKey].assets.map((asset, assetsIndex) => {
							return (
								<div
									className='Gallery__Item Gallery__Item--skeleton'
									key={`${galleryKey}_${assetsIndex}`}
								></div>
							);
					  })
					: null}
			</div>
		);
	}

	let handleVideoMounted = (element) => {
		if (element !== null) {
			element.currentTime = 5;
		}
	};

	function ShowImages() {
		return (
			<Fancybox
				options={{
					Toolbar: false,
					closeButton: 'outside',
					Image: {
						zoom: false,
					},
				}}
			>
				<div className='Gallery__Grid'>
					{galleryData && galleryData[galleryKey] && galleryData[galleryKey].assets
						? galleryData[galleryKey].assets.map((asset, assetsIndex) => {
								if (asset.link?.endsWith('mp4') || asset.link?.includes('player.vimeo.com')) {
									return (
										<a
											className='Gallery__Item Gallery__Item--video'
											key={`${galleryKey}_${assetsIndex}`}
											data-fancybox={`gallery_${galleryKey}`}
											href={asset.link}
										>
											<div className='Gallery__Item__PlayIcon'>
												<PlayIcon />
											</div>

											<SuspenseImg alt={asset.name} src={asset.fileUrl} />
										</a>
									);
								} else if (asset.fileUrl.endsWith('jpg') || asset.fileUrl.endsWith('jpeg')) {
									return (
										<a
											className='Gallery__Item'
											key={`${galleryKey}_${assetsIndex}`}
											data-fancybox={`gallery_${galleryKey}`}
											href={asset.fileUrl}
											data-caption={asset.name}
										>
											<div className='Gallery__Item__Caption'>{asset.name}</div>
											<SuspenseImg alt={asset.name} src={asset.fileUrl} />
										</a>
									);
								} else if (asset.fileUrl.endsWith('mp4')) {
									return (
										<a
											className='Gallery__Item Gallery__Item--video'
											key={`${galleryKey}_${assetsIndex}`}
											data-fancybox={`gallery_${galleryKey}`}
											href={asset.fileUrl}
										>
											<div className='Gallery__Item__PlayIcon'>
												<PlayIcon />
											</div>

											<video width='400' preload='metadata' ref={handleVideoMounted}>
												<source src={asset.fileUrl} type='video/mp4' />
											</video>
										</a>
									);
								} else {
									return null;
								}
						  })
						: null}
				</div>
			</Fancybox>
		);
	}

	return (
		<div className='Gallery'>
			<Helmet>
				<title>{`Gallery - ${state.devName.name}`}</title>
			</Helmet>

			{galleryData && galleryData.length > 1 ? (
				<SubMenu>
					<SubMenuSelect
						list={galleryData}
						value={galleryKey}
						onChange={(e) => changeGallery(e.target.value)}
					></SubMenuSelect>
				</SubMenu>
			) : null}

			<Suspense fallback={<LoadingImages />}>{galleryData ? <ShowImages /> : null}</Suspense>
		</div>
	);
}

export default InteriorTours;
