import React, { useContext } from 'react';
import Iframe from '../components/Iframe';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import { Context } from '../contexts/Store';

import PlotPreview from '../components/PlotPreview';

import '../assets/styles/pages/EcoHouse.scss';

function EcoHouse() {
	const { state } = useContext(Context);

	const mapMargin = state.isMobileDevice ? '2rem' : '4rem';

	return (
		<div className='EcoHouse'>
			<Helmet>
				<title>{`Eco House - ${state.devName.name}`}</title>
			</Helmet>

			{state.menuItems.ecoHouse && state.menuItems.ecoHouse[0] ? (
				<motion.div
					id='virtualtour'
					className='Masterplan__Map'
					key={'MainWrapper'}
					animate={{
						width:
							state.isPreviewPanelOpen && !state.isTabletDevice
								? `calc(70vw - ${mapMargin})`
								: `calc(100vw - ${mapMargin})`,
					}}
					transition={{
						x: { type: 'spring', stiffness: 300, damping: 40 },
					}}
				>
					<Iframe src={state.menuItems.ecoHouse ? state.menuItems.ecoHouse[0].url : ''}></Iframe>
				</motion.div>
			) : null}

			<PlotPreview />
		</div>
	);
}

export default EcoHouse;
