import React, { useState, useContext, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Context } from '../contexts/Store';
import { Helmet } from 'react-helmet';
import Spinner from '../components/LoadingSpinner';
import { SizeMe } from 'react-sizeme';
import SubMenu, { SubMenuSelect } from '../components/SubMenu';
import { ReactComponent as RightArrow } from '../assets/img/long-arrow-right-light.svg';
import { ReactComponent as LeftArrow } from '../assets/img/long-arrow-left-light.svg';
import DownloadIcon from '../components/DownloadIcon';
import '../assets/styles/pages/Brochure.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Brochures Components
 * @prop{array} state //props from context
 * @prop{array} mentItems
 * @state {boolean} currentItem
 * @state {number} itemKey
 * @state {array} items //list through for mobile
 * @state {string} itemSize //pdf file size
 * @state {null} numPage
 * @state {number} //Handle page number
 * @fun {function} onDocumentLoadSuccess
 * @fun {function} prev //Sub menu previous button handler
 * @fun {function} next //Sub menu next button handler
 * @fun {function} saveFile //Download pdf file
 * @fun {function} handleResize //handle resize for screen
 */

function Brochures() {
	const { state } = useContext(Context);
	const [currentItem, setCurrentItem] = useState(false);
	const [itemKey, setItemKey] = useState(0);
	const [items, setItems] = useState([]);
	const [itemSize, setItemSize] = useState('');
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const { brochures } = state.menuItems;
	const [brochurePageSize, setBrochurePageSize] = useState({ width: 0, height: 0 });

	useEffect(() => {
		if (brochures) {
			setCurrentItem(brochures[itemKey]);
			setItems(brochures);
		}
	}, [brochures, itemKey]);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}

	function prev() {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		}
	}

	function next() {
		if (pageNumber < numPages) {
			setPageNumber(pageNumber + 1);
		}
	}

	//get size for Items
	useEffect(() => {
		const bytesToSize = () => {
			const fileSize = currentItem.fileSize;
			const sizes = ['mb'];
			const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024);
			const getMb = Math.floor(bytesToMegaBytes(fileSize));
			setItemSize(`${getMb}${sizes}`);
		};
		bytesToSize();
	});

	const getPDFPageSizes = (pdfPage) => {
		if (
			brochurePageSize.width !== pdfPage.originalWidth ||
			brochurePageSize.height !== pdfPage.originalHeight
		) {
			setBrochurePageSize({
				width: pdfPage.originalWidth,
				height: pdfPage.originalHeight,
			});
		}
	};

	return (
		<div className='Brochure'>
			<Helmet>
				<title>{`Exterior Tour - ${state.devName.name}`}</title>
			</Helmet>

			{!state.isMobileDevice ? (
				<div>
					{(brochures && brochures.length > 1) || numPages > 1 ? (
						<SubMenu>
							{numPages > 1 ? (
								<div className='SubMenu__Item'>
									<div className='PdfControls'>
										<button
											onClick={prev}
											className='PdfControls__Prev'
											disabled={pageNumber === 1 ? true : false}
										>
											<LeftArrow />
										</button>
										<span className='PdfControls__PageStatus'>
											{pageNumber} of {numPages}
										</span>
										<button
											onClick={next}
											className='PdfControls__Next'
											disabled={pageNumber === numPages ? true : false}
										>
											<RightArrow />
										</button>
									</div>
								</div>
							) : null}

							{brochures && brochures.length > 1 && (
								<SubMenuSelect
									list={brochures}
									value={itemKey}
									onChange={(e) => setItemKey(e.target.value)}
								></SubMenuSelect>
							)}
						</SubMenu>
					) : null}
					<SizeMe
						monitorHeight={true}
						refreshRate={128}
						refreshMode={'debounce'}
						render={({ size }) => (
							<div className='ResizeContainer'>
								<div style={{ maxWidth: size.width, maxHeight: size.height }}>
									<Document
										file={currentItem.url}
										onLoadSuccess={onDocumentLoadSuccess}
										loading={<Spinner />}
										renderMode={'canvas'}
									>
										<Page
											pageNumber={pageNumber}
											scale={Math.min(
												(size.width - 60) / brochurePageSize.width,
												(size.height - 60) / brochurePageSize.height
											)}
											renderTextLayer={false}
											onLoadSuccess={getPDFPageSizes}
										/>
									</Document>
								</div>
							</div>
						)}
					/>
				</div>
			) : (
				<div className='Brochure__List'>
					{items.map((item, index) => {
						return (
							<div key={index} className='BrochureCard'>
								<div className='BrochureCard__Body'>
									<h4>{item.title}</h4>
									<span>{itemSize}</span>
								</div>
								<div className='BrochureCard__Icon'>
									<a href={item.url} target='_blank' rel='noreferrer'>
										<DownloadIcon />
									</a>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default Brochures;
