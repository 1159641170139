import React, { useRef, useContext } from 'react';
import Nouislider from 'nouislider-react';

import { Context } from '../contexts/Store';
import { priceFormatter } from '../helpers/utilities';

import Checkbox from './Checkbox';
import Badge from './Badge';

import '../assets/styles/components/Filter.scss';
import 'nouislider/distribute/nouislider.css';

import { updateFilterOptions } from '../contexts/Filters';

function Filter({ filter, index }) {
	const { state, dispatch } = useContext(Context);

	const stateRef = useRef();
	stateRef.current = state.filters;

	const handleCheckboxChange = (filterIndex, valueIndex) => () => {
		let newFilters = JSON.parse(JSON.stringify(state.filters));

		newFilters[filterIndex].values[valueIndex].isChecked =
			!newFilters[filterIndex].values[valueIndex].isChecked;

		//add active state to filter
		if (newFilters[filterIndex].values.some((e) => e.isChecked)) {
			newFilters[filterIndex].active = true;
		} else {
			newFilters[filterIndex].active = false;
		}

		dispatch({
			type: 'setFilters',
			data: newFilters,
		});

		//if this is the status filter reset filter options
		if (newFilters[filterIndex].name === 'plotStatus') {
			updateFilterOptions(state, dispatch, newFilters);
		}
	};

	// Range slider
	const rangeOnSlide = (filterID) => (render, handle, value) => {
		let newRangeLabels = [...state.rangeLabels];
		newRangeLabels[filterID] = value;
		dispatch({
			type: 'setRangeLabels',
			data: newRangeLabels,
		});
	};

	const rangeOnSet = (filterIndex) => (render, handle, value) => {
		let newFilters = JSON.parse(JSON.stringify(stateRef.current));
		newFilters[filterIndex].selectedValues = value;

		// Add active state to filter
		if (
			value[0] !== newFilters[filterIndex].values[0] ||
			value[1] !== newFilters[filterIndex].values[1]
		) {
			newFilters[filterIndex].active = true;
		} else {
			newFilters[filterIndex].active = false;
		}

		dispatch({
			type: 'setFilters',
			data: newFilters,
		});
	};

	if (filter.type === 'range') {
		return (
			<div className='Filter Filter--slider'>
				<Nouislider
					range={{
						min: filter.values[0],
						max: filter.values[1],
					}}
					start={filter.selectedValues}
					step={1000}
					onSet={rangeOnSet(index)}
					onSlide={rangeOnSlide(filter.id)}
					connect
					disabled={false}
				/>
				<div className='Filter__Label'>
					{priceFormatter(state.rangeLabels[filter.id][0])} -{' '}
					{priceFormatter(state.rangeLabels[filter.id][1])}
				</div>
			</div>
		);
	}

	if (filter.type === 'checkbox') {
		return (
			<div className='Filter Filter--checkbox'>
				{filter.values.map((item, i) => {
					return (
						<div className='Filter__Item' key={i}>
							<Checkbox
								key={i}
								onChange={handleCheckboxChange(index, i)}
								checked={item.isChecked}
								id={`checkbox-${index}-${i}`}
								value={item.label}
								label={item.label}
							/>
							{item.color && <Badge style={{ backgroundColor: item.color }} />}
						</div>
					);
				})}
			</div>
		);
	}

	return;
}

export default Filter;
