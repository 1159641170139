import React, { useContext, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

import { Context } from '../contexts/Store';
import { priceFormatter, getStatus, formatCustomFields } from '../helpers/utilities';

import FavButton from './FavButton';
import AvailabilityBadge from './AvailabilityBadge';
import CompareButton from './CompareButton';
import ArrowIcon from './ArrowIcon';
import ExpandButton from './ExpandButton';

import '../assets/styles/components/PlotRow.scss';

function PlotRow({ plot }) {
	const { state } = useContext(Context);
	const [showFields, setShowFields] = useState(false);

	const status = getStatus(state.plotStatuses, 'id', plot.plotStatusId);
	let fields = formatCustomFields(plot.customFields) || [];
	if (plot.plotType.imperialArea !== '') {
		fields = [
			{
				name: 'Area',
				value: `${plot.plotType.imperialArea} sq ft`,
			},
			...fields,
		];
	}

	return (
		<section className='PlotRow'>
			<div className='PlotRow__Inner'>
				<div className='PlotRow__Actions'>
					{fields.length >= 1 && <ExpandButton onClick={() => setShowFields(!showFields)} />}
					<FavButton plotID={plot.id} />
				</div>
				<div className='PlotRow__Name'>Plot {plot.plotNumber}</div>
				<div className='PlotRow__Type'>
					{state.developmentType === 'apartments' ? plot.floorData?.floorName : plot.name}
				</div>
				<div className='PlotRow__Bedrooms'>
					{plot.plotType.numberOfBeds} {parseInt(plot.plotType.numberOfBeds) > 1 ? 'beds' : 'bed'}
				</div>
				<div className='PlotRow__Price'>
					{status.name === 'Available' ? priceFormatter(plot.price) : null}
					{status.name === 'Unreleased' ? '£POA' : null}
				</div>
				<div className='PlotRow__Status'>
					<AvailabilityBadge status={status} />
				</div>
				<div className='PlotRow__Link'>
					{!state.isCompareMode ? (
						<Link className='HomeLink' to={`/homes/${plot.id}`}>
							<span className='HomeLink__Label'>View Home</span> <ArrowIcon />
						</Link>
					) : (
						<CompareButton plotID={plot.id} />
					)}
				</div>
			</div>
			{showFields && fields.length >= 1 && (
				<AnimatePresence>
					<motion.div
						className='PlotRow__Fields'
						initial={{ opacity: 0.5, y: -50 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0.5, y: -50 }}
						transition={{ type: 'tween', delay: 0 }}
					>
						{fields.map((field, key) => (
							<div className='PlotRow__Field' key={key}>
								<p className='PlotRow__Field__Name'>{field.name}</p>
								<p className='PlotRow__Field__Value'>{field.value}</p>
							</div>
						))}
					</motion.div>
				</AnimatePresence>
			)}
		</section>
	);
}

export default PlotRow;
