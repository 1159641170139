import React, { useContext } from 'react';
import { Context } from '../contexts/Store';
import '../assets/styles/pages/EpcCalc.scss';

function EpcCalc() {
	const { state } = useContext(Context);

	return (
		<>
			<div className='epc-calc'>
				<iframe className='epc-calc__container' src={state.menuItems.epcCalc}></iframe>
			</div>
		</>
	);
}

export default EpcCalc;
