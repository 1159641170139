import React from 'react';
import { Link } from 'react-router-dom';

import '../assets/styles/components/ActionButton.scss';
import { ReactComponent as IconFloorplan } from '../assets/img/floorplan.svg';
import { ReactComponent as IconHouseUser } from '../assets/img/house-user.svg';
import { ReactComponent as IconStreetView } from '../assets/img/streetview.svg';
import { ReactComponent as IconSpecification } from '../assets/img/list-check-light.svg';
import { ReactComponent as IconIncentive } from '../assets/img/badge-percent-light.svg';

function ActionButton({ variant = 'primary', to, onClick, icon, ...props }) {
	const showActionButton = () => {
		switch (icon) {
			case 'floorplan':
				return <IconFloorplan />;

			case 'house-user':
				return <IconHouseUser />;

			case 'streetview':
				return <IconStreetView />;
			case 'specification':
				return <IconSpecification />;
			case 'incentive':
				return <IconIncentive />;

			default:
				throw Error('You must provide a icon name for ActionButton');
		}
	};

	return to ? (
		<Link to={to} role='button' className={`ActionButton ActionButton--${variant}`} {...props}>
			{icon && <span className='ActionButton__Icon'>{showActionButton()}</span>}
			<span className='ActionButton__Text'>{props.children}</span>
		</Link>
	) : (
		<div
			role='button'
			className={`ActionButton ActionButton--${variant}`}
			onClick={onClick}
			{...props}
		>
			{icon && <span className='ActionButton__Icon'>{showActionButton()}</span>}
			<span className='ActionButton__Text'>{props.children}</span>
		</div>
	);
}

export default ActionButton;
