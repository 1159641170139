import { useContext, useState, useEffect } from 'react';
import { Context } from '../contexts/Store';
import '../assets/styles/components/Locator.scss';

function Locator(props) {
	const { containerId, onChange } = props;
	const { state } = useContext(Context);
	const [position, setPosition] = useState();
	const [siteplan, setSiteplan] = useState(null);
	const [innerPosition, setInnerPosition] = useState(null);

	const getPlotContainerDataForId = (obj, thisContainerId) => {
		obj.forEach((plotContainerData) => {
			let siteplanSrc = plotContainerData.interactiveAssetURI;
			plotContainerData.interactiveRegions.every((arrayItem) => {
				if (arrayItem.targetId === thisContainerId) {
					const newPosition = {
						left: (arrayItem.startX + arrayItem.width / 2) * 100.0 + '%',
						top: (arrayItem.startY + arrayItem.height / 2) * 100.0 + '%',
					};
					setPosition(newPosition);

					const siteplan = new Image();
					siteplan.src = siteplanSrc;
					siteplan.onload = () => {
						setInnerPosition({
							aspectRatio: `${siteplan.width}/${siteplan.height}`,
						});
						onChange;
					};

					setSiteplan(siteplan);
					return false;
				}
				return true;
			});
			if (Object.prototype.hasOwnProperty.call(plotContainerData, 'plotContainers')) {
				getPlotContainerDataForId(plotContainerData.plotContainers, thisContainerId);
			}
		});
	};

	useEffect(() => {
		if (state.plotContainers) {
			getPlotContainerDataForId(state.plotContainers, containerId);
		}
	}, [state.plotContainers]);

	return siteplan && innerPosition ? (
		<div className='Locator'>
			<div className='Locator__Inner' style={innerPosition}>
				<img src={siteplan.src} alt={siteplan.src} className='Locator__Image' />
				<div className='Locator__Marker' style={position}></div>
			</div>
		</div>
	) : null;
}

export default Locator;
