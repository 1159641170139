import React, { useEffect, useState } from 'react';
import { DropTarget } from 'react-drag-drop-container';

function VrMarker(props) {
	const { posX, posY, targId, scale, vrMarkerActive, hiLightColour } = props;

	const [divStyle, setDivStyle] = useState({
		top: `calc(${posY}% - ${9}px)`,
		left: posX + '%',
		transition: 'all 200ms ease-in-out',
		border: '1px solid rgba(255, 255, 255, 1)',
		zIndex: 10,
	});

	useEffect(() => {
		setDivStyle((thisStyle) => ({
			...thisStyle,
			transform: 'scale(' + 3.0 / parseFloat(scale) + ')',
		}));
	}, [scale]);

	function dragEntered() {
		setDivStyle((thisStyle) => ({
			...thisStyle,
			transform: 'scale(' + 3.2 / parseFloat(scale) + ')',
			transition: 'all 200ms ease-in-out',
			border: '1px solid ' + hiLightColour,
		}));
	}

	function dragLeftElement() {
		setDivStyle((thisDivStyle) => ({
			...thisDivStyle,
			transform: 'scale(' + 3.0 / parseFloat(scale) + ')',
			transition: 'all 200ms ease-in-out',
			border: '1px solid rgba(255, 255, 255, 1)',
			zIndex: 10,
		}));
	}

	return (
		<>
			{vrMarkerActive ? (
				<DropTarget
					targetKey='vr_marker'
					dropData={targId}
					onDragEnter={dragEntered}
					onDragLeave={dragLeftElement}
				>
					<div className='vrMarker' style={divStyle} />
				</DropTarget>
			) : null}
		</>
	);
}

export default VrMarker;
