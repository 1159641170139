import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../contexts/Store';
import ToggleButton from '../components/ToggleButton';

function FavButton({ plotID, ...props }) {
	const { state, dispatch } = useContext(Context);

	const [isFav, setIsFav] = useState(false);

	function handleClick(plotID, e) {
		e.stopPropagation();
		if (isFav) {
			dispatch({
				type: 'removeFavPlot',
				data: plotID,
			});
		} else {
			dispatch({
				type: 'addFavPlot',
				data: plotID,
			});
		}
	}

	useEffect(() => {
		if (state.favPlots && plotID && state.favPlots.includes(plotID)) {
			setIsFav(true);
		} else {
			setIsFav(false);
		}
	}, [state.favPlots, plotID]);

	return (
		<ToggleButton
			icon={isFav ? 'heart' : 'heart-light'}
			onClick={(e) => handleClick(plotID, e)}
			size='sm'
		>
			{props.children}
		</ToggleButton>
	);
}

export default FavButton;
