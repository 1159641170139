import React, { useState, useContext, useEffect } from 'react';
import { motion } from 'framer-motion';

import { Context } from '../contexts/Store';
import { getPlotById } from '../helpers/utilities';

import Button from './Button';

import '../assets/styles/components/CompareComboBar.scss';
import { ReactComponent as IconClose } from '../assets/img/close.svg';
import { ReactComponent as IconCompare } from '../assets/img/compare.svg';

function CompareComboBar() {
	const { state, dispatch } = useContext(Context);
	const [showButton, setShowButton] = useState(false);
	const items = Array.from({ ...state.comparePlots, length: 2 });

	useEffect(() => {
		setShowButton(state.comparePlots.length >= 2);
	}, [state.comparePlots]);

	return (
		<motion.div className='CompareComboBar' initial={{ y: 0 }} animate={{ y: 20 }}>
			<div className='CompareComboBar__Inner'>
				<span className='CompareComboBar__Label'>
					<IconCompare />
					Select two homes to compare
				</span>

				{items.map((item, key) => {
					const plot = getPlotById(state.plots, item);
					if (item) {
						return (
							<div
								className='CompareComboBar__Item'
								onClick={() => dispatch({ type: 'toggleComparePlot', data: item })}
								key={key}
							>
								Plot {plot.plotNumber}
								<IconClose />
							</div>
						);
					} else {
						return <div className='CompareComboBar__Item' key={key}></div>;
					}
				})}

				{showButton && (
					<Button to='/compare' size='sm' variant='secondary'>
						Compare
					</Button>
				)}
			</div>
		</motion.div>
	);
}

export default CompareComboBar;
