import React, { useState, useContext, useEffect } from 'react';

import { get } from '../adapters/xhr';
import { Context } from '../contexts/Store';

import Input from './Input';
import Button from './Button';

import '../assets/styles/components/LoginForm.scss';

function LoginForm() {
	const { state, dispatch } = useContext(Context);
	const [username, setUsername] = useState();
	const [gdprText, setGdprText] = useState();

	useEffect(() => {
		if (!state.loggedIn) {
			get(`/v1/GetGdprInformation/${state.devId}`).then((response) => {
				setGdprText(response.data.gdprConsentText);
			});
		}
	}, [state.loggedIn]);

	async function handleSubmit(e) {
		e.preventDefault();

		try {
			const response = await get(`/v2/GetCustomerFavourites/${username}`);

			dispatch({
				type: 'login',
				data: response.data,
			});

			dispatch({
				type: 'syncFavPlots',
				data: response.data.customerFavoritePlotIds,
			});
		} catch (error) {
			if (error.response.data === 'Customer not found.') {
				addUser();
			}
		}
	}

	function addUser() {}

	function handleLogout() {
		dispatch({
			type: 'logout',
		});

		dispatch({
			type: 'updateLocalFavPlots',
			data: [],
		});
	}

	if (!state.loggedIn) {
		return (
			<form className='LoginForm' onSubmit={handleSubmit}>
				<div className='LoginForm__Content'>
					<h2>Your Account</h2>
					<p>
						Please enter your email address to save or retrieve the favourites within your account.
					</p>
				</div>
				<div className='LoginForm__Form'>
					<label className='LoginForm__Label' htmlFor='email-address'>
						Email address:
					</label>
					<Input
						type='email'
						name='username'
						id='email-address'
						onChange={(e) => setUsername(e.target.value)}
						variant='secondary'
						autoComplete='off'
					/>
					<Button variant='secondary' size='sm'>
						Sign in
					</Button>
				</div>
				{gdprText && <p className='LoginForm__Consent'>{gdprText}</p>}
			</form>
		);
	} else {
		return (
			<div className='LoginForm LoginForm--logged-in'>
				<p>
					Logged in as <b>{state.user.email}</b>
				</p>
				<Button onClick={handleLogout}>Log out</Button>
			</div>
		);
	}
}

export default LoginForm;
