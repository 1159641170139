import React, { useContext } from 'react';
import { Context } from '../contexts/Store';
import '../assets/styles/components/SiteToggleButton.scss';

const SiteToggleButton = () => {
	const { state, dispatch } = useContext(Context);
	const buttonLabel = state.siteplanToggle ? '2D' : '3D';

	function toggleSiteplan() {
		dispatch({
			type: 'siteplanToggle',
			data: !state.siteplanToggle,
		});
	}

	return (
		<div className='SiteToggleButton' onClick={() => toggleSiteplan()}>
			{buttonLabel}
		</div>
	);
};

export default SiteToggleButton;
