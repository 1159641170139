import React, { useState } from 'react';
import ActionButton from './ActionButton';
import Modal from './Modal';

function InteriorTourButton({ tour }) {
	const [isShown, setIsShown] = useState(false);

	if (!tour) return null;

	const url = new URL(tour.url);

	if (url.hostname === 'my.matterport.com') {
		url.searchParams.set('nt', 0);
		url.searchParams.set('play', 1);
		url.searchParams.set('qs', 1);
		url.searchParams.set('brand', 0);
		url.searchParams.set('hr', 0);
		url.searchParams.set('wh', 0);
		url.searchParams.set('title', 0);
		url.searchParams.set('vr', 0);
	}

	return (
		<>
			{isShown && (
				<Modal onClose={() => setIsShown(false)}>
					<iframe src={url}></iframe>
				</Modal>
			)}

			<ActionButton variant='primary' icon='house-user' onClick={() => setIsShown(!isShown)}>
				Interior Tour
			</ActionButton>
		</>
	);
}

export default InteriorTourButton;
