//utilities
import {
	stringToSlug,
	fromPrice,
	toPrice,
	getStatusID,
	dynamicSort,
	findFloorplans,
} from './utilities';

//Status ID
function plotsToTypes(plotsData, plotStatuses) {
	let types = plotsData.map((type) => {
		const typeContainer = {};
		typeContainer['id'] = type.plotType.id;
		typeContainer['assets'] = type.plotType.assets;
		typeContainer['description'] = type.plotType.description;
		typeContainer['dimensions'] = type.plotType.dimensions;
		typeContainer['disclaimerText'] = type.plotType.disclaimerText;
		typeContainer['imperialArea'] = type.plotType.imperialArea;
		typeContainer['metricArea'] = type.plotType.metricArea;
		typeContainer['name'] = type.plotType.name;
		typeContainer['slug'] = stringToSlug(type.plotType.name);
		typeContainer['numberOfBeds'] = type.plotType.numberOfBeds;
		typeContainer['printPdfURI'] = type.plotType.printPdfURI;

		//get list of prices min and max
		typeContainer['fromPrice'] = fromPrice(
			plotsData
				.filter((plot) => {
					if (
						plot.plotTypeId === type.plotTypeId &&
						plot.plotStatusId === getStatusID(plotStatuses, 'Available')
					) {
						return true;
					}

					return false; // skip
				})
				.map((plot) => {
					let prices = [];
					prices.push(plot.price);

					return prices;
				})
		);

		typeContainer['toPrice'] = toPrice(
			plotsData
				.filter((plot) => {
					if (
						plot.plotTypeId === type.plotTypeId &&
						plot.plotStatusId === getStatusID(plotStatuses, 'Available')
					) {
						return true;
					}
					return false; // skip
				})
				.map((plot) => {
					let prices = [];
					prices.push(plot.price);

					return prices;
				})
		);

		//sort images
		let sortedImages = type.plotType.assets.slice().sort(dynamicSort('order'));
		typeContainer['sortedImages'] = sortedImages;

		//get floorplans
		let floorplans = findFloorplans(sortedImages);

		// add the floorplans
		typeContainer['floorplans'] = floorplans;

		typeContainer['plots'] = plotsData
			.filter((plot) => {
				if (plot.plotTypeId === type.plotTypeId) {
					return true;
				}
				return false; // skip
			})
			.map((plot) => {
				// const plotContainer = {}
				// plotContainer["id"] = plot.id
				// plotContainer["plotNumber"] = plot.plotNumber
				// plotContainer["plotTypeId"] = plot.plotTypeId
				// plotContainer["name"] = plot.name
				// plotContainer["plotStatusId"] = plot.plotStatusId
				// plotContainer["price"] = plot.price
				// plotContainer["description"] = plot.description

				//get the current plot phase if it exists
				let phase = null;
				let phaseField = null;
				if (plot.customFields && plot.customFields.length > 0) {
					phaseField = plot.customFields.find(
						({ displayName }) => stringToSlug(displayName) === `phase`
					);
				}
				if (phaseField) {
					phase = phaseField.fieldValue;
				} else {
					phase = false;
				}

				return { ...plot, phase };
			});

		return typeContainer;
	});

	//remove duplicates by ID
	const filteredTypes = types.reduce((acc, current) => {
		const x = acc.find((item) => item.id === current.id);

		if (!x) {
			return acc.concat([current]);
		} else {
			return acc;
		}
	}, []);

	return filteredTypes;
}

export default plotsToTypes;
