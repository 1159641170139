import React, { useContext, useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Context } from '../contexts/Store';
import ToggleButton from './ToggleButton';
import '../assets/styles/components/Disclaimer.scss';

function Disclaimer() {
	const { state } = useContext(Context);
	const { hideHeader, isMobileDevice, globalDisclaimer } = state;
	const [showDisclaimer, setShowDisclaimer] = useState(true);
	let timeOut;

	useEffect(() => {
		if (!isMobileDevice && !showDisclaimer) setShowDisclaimer(true);
		if (!hideHeader || isMobileDevice) return;

		startTimer();
	}, [hideHeader, isMobileDevice]);

	function startTimer() {
		clearInterval(timeOut);
		timeOut = setInterval(() => functionToRunOnTimeout(), 20000);
	}

	function functionToRunOnTimeout() {
		clearInterval(timeOut);
		setShowDisclaimer(false);
	}

	return (
		<AnimatePresence>
			{showDisclaimer ? (
				<motion.div
					className='Disclaimer'
					initial={{ y: 0, x: '-50%' }}
					animate={{ y: 20, x: '-50%' }}
					exit={{ opacity: 0 }}
				>
					<span className='Disclaimer__Text'>{globalDisclaimer}</span>
					{isMobileDevice ? (
						<ToggleButton
							icon='close'
							variant='secondary'
							size='sm'
							onClick={() => setShowDisclaimer(false)}
						/>
					) : null}
				</motion.div>
			) : null}
		</AnimatePresence>
	);
}

export default Disclaimer;
