import React, { useContext } from 'react';
import QRCode from 'react-qr-code';

import { Context } from '../contexts/Store';

import '../assets/styles/pages/Favourites.scss';

function FavouritesQRCode() {
	const { state } = useContext(Context);

	const fgColor = state.devStyle.container.text || '#676767';
	const bgColor = state.devStyle.container.toolbarPrimary || '#FFFFFF';

	let qrCodeUrl = new URL(window.location.href + '/sync');
	if (state.favPlots) {
		qrCodeUrl.searchParams.append('plots', state.favPlots.join(','));
	}

	return (
		<div className='FavouritesQRCode'>
			<h2>Take your favourites with you</h2>
			<p>Scan the code below to transfer your favourites to your device.</p>
			<div className='FavouritesQRCode__QRCode'>
				<QRCode value={qrCodeUrl.href} size={140} fgColor={fgColor} bgColor={bgColor} />
			</div>
		</div>
	);
}

export default FavouritesQRCode;
