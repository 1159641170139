import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import '../assets/styles/components/ImageSlider.scss';

function ImageSlider({ images, imagesCover = false, autoHeight = false }) {
	if (imagesCover) {
		images = images.map((image, key) => (
			<div key={key} className='ImageSlider__Image__Contianer'>
				<div
					className='ImageSlider__Image__Contianer__Background'
					style={{ backgroundImage: `url(${image.fileUrl})` }}
				></div>
				<img src={image.fileUrl} alt={image.name} loading='lazy' className='ImageSlider__Image' />
				{image.caption && <div className='ImageSlider__Caption'>{image.caption}</div>}
			</div>
		));
	} else {
		images = images.map((image) => (
			<>
				<img src={image.fileUrl} alt={image.name} loading='lazy' className='ImageSlider__Image' />
				{image.caption && <div className='ImageSlider__Caption'>{image.caption}</div>}
			</>
		));
	}

	return (
		<Swiper
			className={`ImageSlider ${imagesCover && 'ImageSlider--imagesCover'}`}
			modules={[Pagination]}
			pagination={{ clickable: true }}
			slidesPerView={1}
			autoHeight={autoHeight}
		>
			{images.map((image, key) => (
				<SwiperSlide key={key}>{image}</SwiperSlide>
			))}
		</Swiper>
	);
}

export default ImageSlider;
