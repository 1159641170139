import React from 'react';
import { ReactComponent as HeartIcon } from '../assets/img/heart-solid.svg';
import '../assets/styles/components/FavIcon.scss';

export function FavIcon() {
	return (
		<div className='fav-icon'>
			<HeartIcon />
		</div>
	);
}
