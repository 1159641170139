import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';

import { Context } from '../contexts/Store';

import Checkbox from '../components/Checkbox';
import Input from '../components/Input';

import '../assets/styles/pages/AppSettings.scss';

function SetMarketingSuite() {
	const { state } = useContext(Context);

	let { key } = useParams();
	if (key !== window['runConfig'].devKEY) location.replace('/');

	const [cookies, setCookie] = useCookies(['inMarketingSuite', 'haveLights', 'lightServerAddress']);

	const [inMarketingSuite, setInMarketingSuite] = useState(
		cookies.inMarketingSuite === 'true' ? true : false
	);

	const [haveLights, setHaveLights] = useState(cookies.haveLights === 'true' ? true : false);

	const [lightServerAddress, setLightServerAddress] = useState(cookies.lightServerAddress);

	useEffect(() => {
		if (inMarketingSuite) {
			setCookie('inMarketingSuite', 'true', { path: '/', maxAge: 31536000 });
		} else {
			setCookie('inMarketingSuite', 'false', { path: '/', maxAge: 31536000 });
			setHaveLights(false);
		}
	}, [inMarketingSuite]);

	useEffect(() => {
		if (haveLights) {
			setCookie('haveLights', 'true', { path: '/', maxAge: 31536000 });
		} else {
			setCookie('haveLights', 'false', { path: '/', maxAge: 31536000 });
			setLightServerAddress('');
		}
	}, [haveLights]);

	useEffect(() => {
		if (lightServerAddress) {
			setCookie('lightServerAddress', lightServerAddress, {
				path: '/',
				maxAge: 31536000,
			});
		} else {
			setCookie('lightServerAddress', '', {
				path: '/',
				maxAge: 31536000,
			});
		}
	}, [lightServerAddress]);

	return (
		<div className='AppSettings'>
			<Helmet>
				<title>{`App Settings - ${state.devName.name}`}</title>
			</Helmet>
			<h1 className='AppSettings__Heading'>App Settings</h1>
			{key === window['runConfig'].devKEY && (
				<>
					<Checkbox
						id='set-marketing-suite'
						checked={inMarketingSuite}
						onChange={() => setInMarketingSuite(!inMarketingSuite)}
						label='Set location as marketing suite'
					/>
					<Checkbox
						id='set-have-lights'
						checked={haveLights}
						onChange={() => setHaveLights(!haveLights)}
						disabled={!inMarketingSuite}
						label='Enable light server'
					/>
					{haveLights && (
						<div className='AppSettings__Input'>
							<Input
								id='set-light-server-address'
								value={lightServerAddress}
								onChange={(event) => setLightServerAddress(event.target.value)}
							/>
							<label htmlFor='set-light-server-address'>
								Please enter the IP address and port e.g. wss://192.168.1.88:443{' '}
							</label>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default SetMarketingSuite;
