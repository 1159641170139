import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { Context } from '../contexts/Store';
import { dynamicSort, stringToSlug, findFloorplans } from '../helpers/utilities';
import { Helmet } from 'react-helmet';
import '../assets/styles/pages/Plot.scss';
import PlotHeader from '../components/PlotHeader';
import ActionButton from '../components/ActionButton';
import BackButton from '../components/BackButton';
import FavButton from '../components/FavButton';
import ToggleButton from '../components/ToggleButton';
import SubMenu, { SubMenuViewTabs } from '../components/SubMenu';
import InteriorTourButton from '../components/InteriorTourButton';
import Locator from '../components/Locator';
import DimsCards from '../components/DimsCards';
import FloorSelector from '../components/FloorSelector';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import MapControl from '../components/MapControl';
import CustomFields from '../components/CustomFields';
import { ReactComponent as BackArrow } from '../assets/img/long-arrow-left-light.svg';
import PlotSpecificationButton from '../components/PlotSpecificationButton';
import PlotIncentivesButton from '../components/PlotIncentivesButton';

function Plot() {
	const { state, dispatch } = useContext(Context);
	const plotID = parseInt(useParams().id);
	const [plot, setPlot] = useState();
	const navigate = useNavigate();

	const [activeView, setActiveView] = useState('overview');
	const [activeFloorplan, setActiveFloorplan] = useState(0);
	const [floorPlanPan, setFloorPlanPan] = useState(false);

	const views = [
		{
			label: 'Overview',
			slug: 'overview',
		},
		{
			label: 'Floorplans',
			slug: 'floorplans',
		},
	];

	const handleCompareClick = () => {
		if (state.comparePlots.length === 2) {
			dispatch({
				type: 'resetComparePlots',
			});
		}
		dispatch({
			type: 'toggleComparePlot',
			data: plotID,
		});
		navigate('/compare');
	};

	useEffect(() => {
		state.plots.filter((el) => {
			if (el.id === plotID) {
				let images = el.plotType.assets.slice().sort(dynamicSort('order'));

				if (el.plotSpecificAssets !== undefined) {
					images.push(...el.plotSpecificAssets);
				}

				el = { ...el, images };

				//get floorplans
				let floorplans = findFloorplans(images);

				// add the floorplans
				el = { ...el, floorplans };

				//get the current plot phase
				let phase = null;
				if (el.customFields && el.customFields.length > 0) {
					phase = el.customFields.find(({ displayName }) => stringToSlug(displayName) === `phase`);
				}
				el = { ...el, phase };

				//find exterior tour view
				let exteriorTourView = null;
				if (state.plotVRs) {
					exteriorTourView = state.plotVRs.find(
						({ plot }) => stringToSlug(plot) === `plot-${el.plotNumber}`
					);
				}
				el = { ...el, exteriorTourView };

				//find interior tour
				let interiorTour = null;
				const typeSlug = stringToSlug(el.plotType.name);
				if (state.menuItems.interiorTours) {
					interiorTour = state.menuItems.interiorTours.find((el) => el.slug === typeSlug);
				}
				el = { ...el, interiorTour, typeSlug };

				let specificationLink = null;
				if (el.plotType?.specificationLink) {
					specificationLink = el.plotType.specificationLink;
				}
				el = { ...el, specificationLink };

				let incentivesLink = null;
				if (state.menuItems.incentives) {
					incentivesLink = state.menuItems.incentives;
				}
				el = { ...el, incentivesLink };

				setPlot(el);
			}
		});
	}, [state.plots, plotID, state.menuItems]);

	//set current light
	useEffect(() => {
		if (plot) {
			dispatch({
				type: 'setCurrentLight',
				data: { ID: plot.lightId, status: 'on' },
			});
		}
		return () => {
			dispatch({
				type: 'turnOffCurrentLight',
			});
		};
	}, [plot]);

	if (!plot) {
		return <>Loading</>;
	}

	// Check if plot is an apartment by checking if it has floors
	let isApartment = plot.floorData?.floorName;
	let apartmentBlock = plot.floorData?.blockId;

	return (
		<main className='Plot'>
			<Helmet>
				<title>{`Plot ${plot.plotNumber} - ${state.devName.name}`}</title>
			</Helmet>

			<SubMenu>
				{!state.isMobileDevice && (
					<div className='SubMenu__Item'>
						<BackButton />
					</div>
				)}
				<div className='SubMenu__Item'>
					<ToggleButton icon='compare' size='sm' onClick={handleCompareClick}>
						Compare
					</ToggleButton>
				</div>
				{!state.isMobileDevice && (
					<div className='SubMenu__Item'>
						<FavButton plotID={plot.id}>Favourite</FavButton>
					</div>
				)}
				{plot.floorplans.length > 0 && (
					<SubMenuViewTabs
						views={views}
						activeView={activeView}
						setActiveView={setActiveView}
					></SubMenuViewTabs>
				)}
			</SubMenu>

			<PlotHeader plot={plot} />

			{state.isMobileDevice && (
				<div className='Plot__MobileActions'>
					<BackButton />
					<FavButton plotID={plot.id}>Favourite</FavButton>
				</div>
			)}

			{activeView === 'overview' && (
				<div className='Plot__Main'>
					<div className='Plot__Gallery'>
						<div className='Plot__Images'>
							{plot.images.map((image, i) => {
								if (image.name.indexOf('FP_') != -1) return; // Omit floorplan images
								return (
									<div className='Plot__Image' key={i}>
										<img src={image.fileUrl} alt={image.name} loading='lazy' />
									</div>
								);
							})}
						</div>
					</div>
					<div className='Plot__Description'>
						{plot.customFields && <CustomFields fields={plot.customFields} />}

						{parse(plot.description)}

						{(plot.interiorTour ||
							plot.exteriorTourView ||
							plot.specificationLink ||
							plot.incentivesLink) && (
							<div className='Plot__Actions'>
								{plot.interiorTour && <InteriorTourButton tour={plot.interiorTour} />}

								{plot.exteriorTourView && (
									<ActionButton
										variant='secondary'
										icon='streetview'
										to={`/exterior-tour/${
											plot.phase ? stringToSlug(plot.phase.displayName) : 'current'
										}/${plot.exteriorTourView.arg}`}
									>
										View Street
									</ActionButton>
								)}
								<PlotSpecificationButton link={plot.specificationLink} />
								<PlotIncentivesButton link={plot.incentivesLink} />
							</div>
						)}
					</div>
				</div>
			)}

			{activeView === 'floorplans' && (
				<div className='Plot__Floorplans'>
					<div className='Plot__Floorplans__Locators'>
						<div className='Plot__Floorplans__Locators__Locator'>
							<div className='Plot__Floorplans__Locators__Caption'>Site Location</div>
							<Locator
								containerId={isApartment && apartmentBlock !== null ? apartmentBlock : plotID}
							/>
							<Link
								className='Plot__Floorplans__Locators__Link'
								to={`/site/${
									plot.developmentPhase
										? plot.developmentPhase.plotContainerId
										: state.plotContainers[0] && state.plotContainers[0].id
								}`}
							>
								<BackArrow className='Plot__Floorplans__Locators__Link__Icon' /> Site Plan
							</Link>
						</div>
						{isApartment && (
							<div className='Plot__Floorplans__Locators__Locator'>
								<div className='Plot__Floorplans__Locators__Caption'>Floor Location</div>
								<Locator containerId={plotID} />
								<Link className='Plot__Floorplans__Locators__Link' to={`/site/${apartmentBlock}`}>
									<BackArrow className='Plot__Floorplans__Locators__Link__Icon' /> View floor
								</Link>
							</div>
						)}
					</div>
					<div className='Plot__Floorplans__Floorplan'>
						<TransformWrapper
							initialScale={1}
							centerOnInit
							maxScale={4}
							minScale={1}
							wheel={{
								disabled: true,
							}}
							panning={{
								disabled: !floorPlanPan && window.innerWidth <= 740 ? true : false,
							}}
						>
							{({ zoomIn, zoomOut, centerView, state }) => {
								return (
									<React.Fragment>
										<div className='Plot__Floorplans__PinchZoomControls'>
											<MapControl
												variant='zoom-in'
												onClick={() => {
													zoomIn();
													if (!floorPlanPan) {
														setFloorPlanPan(true);
													}
												}}
												title='Zoom in'
												disabled={state.scale === 4}
											/>
											<MapControl
												variant='zoom-out'
												onClick={() => {
													zoomOut();
													if (state.scale - 0.65 <= 1 && floorPlanPan) {
														setFloorPlanPan(false);
													}
												}}
												title='Zoom out'
												disabled={state.scale <= 1}
											/>
											<MapControl
												variant='center-map'
												onClick={() => {
													centerView(1, 100, 'easeOut');
													if (floorPlanPan) {
														setFloorPlanPan(false);
													}
												}}
												title='Center map'
											/>
										</div>
										<div className='Plot__Floorplans__Floorplan__Caption'>
											{plot.floorplans[activeFloorplan].caption}
										</div>
										<TransformComponent
											wrapperStyle={{
												width: '100%',
												height: '100%',
												borderRadius: 'calc(var(--theme-border-radius) * 2)',
												WebkitMaskImage: '-Webkit-radial-gradient(white, black)',
											}}
										>
											<img
												className='Plot__Floorplans__Floorplan__Image Plot__Floorplans__Floorplan__Image--nopan'
												src={plot.floorplans[activeFloorplan].fileUrl}
												alt={plot.floorplans[activeFloorplan].name}
												onLoad={() => centerView(1, 100, 'easeOut')}
											/>
										</TransformComponent>
									</React.Fragment>
								);
							}}
						</TransformWrapper>
					</div>
					<div className='Plot__Floorplans__Controls'>
						<DimsCards data={plot.plotType} />
						{plot.floorplans.length > 1 && (
							<FloorSelector
								floors={plot.floorplans}
								activeFloor={activeFloorplan}
								setActiveFloor={setActiveFloorplan}
							/>
						)}
					</div>
				</div>
			)}
		</main>
	);
}

export default Plot;
