import React from 'react';

import '../assets/styles/components/LoadingSpinner.scss';

function LoadingSpinner({ inline = false, height = null }) {
	return height != null ? (
		<div className='LoadingSpinner-container' style={{ height: height }}>
			<div
				className={`
          LoadingSpinner 
          ${!inline && 'LoadingSpinner--mauto'}
      `}
			>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	) : (
		<div
			className={`
          LoadingSpinner 
          ${!inline && 'LoadingSpinner--mauto'}
      `}
		>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}

export default LoadingSpinner;
