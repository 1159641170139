import { useContext, useState, useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';

import { Context } from '../contexts/Store';
import { getPlotById, getStatusID } from '../helpers/utilities';

import useCheckDevice from '../hooks/useCheckDevice';
import PlotMarkerBP from '../components/PlotMarkerBP';
import PlotPreview from '../components/PlotPreview';
import MapControl from '../components/MapControl';
import AvailabilityBadge from '../components/AvailabilityBadge';
import Alert from '../components/Alert';
import Locator from '../components/Locator';
import SubMenu from '../components/SubMenu';
import Breadcrumbs from '../components/Breadcrumbs';

import '../assets/styles/components/Masterplan.scss';

function Blockplan(props) {
	const { id } = props;
	let { state, dispatch } = useContext(Context);
	const transformComponentRef = useRef(null);
	const { isMobile } = useCheckDevice();

	const imageRes = {
		size: isMobile ? 500 : 700,
	};

	const maxZoomScale = () => {
		return isMobile ? 8 : 2;
	};

	const [blockPlanImage, setBlockplanImage] = useState('');
	const [plotMarkers, setPlotMarkers] = useState([]);
	const [thisBuildingName, setThisBuildingName] = useState('');
	const [cgiImage, setCgiImage] = useState('');
	const [cgiImagePath, setCgiImagePath] = useState('');
	const [displayCgiImage, setDisplayCgiImage] = useState(false);

	const [floorsArray, setFloorsArray] = useState([]);
	const [floorName, setFloorName] = useState('');

	const [scale, setScale] = useState(1.0);

	const [selectedTargetId, setSelectedTargetId] = useState();

	const [currentFloorNumber, setCurrentFloorNumber] = useState(0);
	const [prevFloorNumber, setPrevFloorNumber] = useState(0);
	const [floorAvailabilityCount, setFloorAvailabilityCount] = useState(0);
	const [showElevation, setShowElevation] = useState(!state.isMobileDevice);

	const [floorDirection, setDirection] = useState(0);
	const [isMounted, setIsMounted] = useState(false);
	const [isPanning, setIsPanning] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);

	const imageRef = useRef(null);
	const floorsRef = useRef(null);

	let interactiveTimer;

	const scaleValue = imageRes?.size / imageRef?.current?.naturalWidth;
	const calcImageHeight = scaleValue * imageRef?.current?.naturalHeight;
	const difference = imageRes?.size - calcImageHeight;
	const topMargin = difference / 2;

	const calcImageWidth = scaleValue * imageRef?.current?.naturalWidth;

	const variants = {
		enter: (floorDirection) => {
			return {
				zIndex: 1,
				y: floorDirection < 0 ? '50%' : '-50%',
				scale: 1,
				opacity: 0.2,
			};
		},
		center: {
			y: 0,
			opacity: 1.0,
		},
		exit: () => {
			return {
				zIndex: 0,
				y: floorDirection > 0 ? '50%' : '-50%',
				scale: 0.4,
				opacity: 0.2,
			};
		},
	};

	useEffect(() => {
		setIsMounted(true);

		window.addEventListener('resize', () => {
			CenterView();
		});

		return () => {
			setIsMounted(false);
			dispatch({ type: 'closePlotPreview' });

			window.removeEventListener('resize', () => {
				CenterView();
			});
		};
	}, []);

	useEffect(() => {
		getPlotContainerDataForId(state.plotContainers, id);
	}, [id]);

	useEffect(() => {
		zoomToPlotMarker();
	}, [state.selectedPlotMarkerId]);

	const animateFloorplan = (newDirection) => {
		setDirection(newDirection);
	};

	useEffect(() => {
		if (scale == 1) dispatch({ type: 'closePlotPreview' });
		//if (scale <= 1.0) CenterView();
	}, [scale]);

	useEffect(() => {
		CenterView();
	}, [blockPlanImage]);

	useEffect(() => {
		if (displayCgiImage) {
			setCgiImage(cgiImagePath);
		}
	}, [displayCgiImage, cgiImagePath]);

	useEffect(() => {
		if (currentFloorNumber < prevFloorNumber) {
			animateFloorplan(-1);
		} else {
			animateFloorplan(1);
		}
		setPrevFloorNumber(currentFloorNumber);
	}, [currentFloorNumber]);

	useEffect(() => {
		if (!state.isPreviewPanelOpen) CenterView();
	}, [state.isPreviewPanelOpen]);

	useEffect(() => {
		if (selectedTargetId && imageLoaded)
			getPlotContainerDataForId(state.plotContainers, selectedTargetId);
	}, [state.filteredHomes, imageLoaded]);

	useEffect(() => {
		if (!floorsArray.length) return;

		const e = Array.from(floorsArray);
		e.sort((a, b) => a.floorNumber - b.floorNumber);

		const thisFloor = e.find((item) => {
			return item.availabilityCount > 0;
		});

		if (!thisFloor) return;

		getPlotContainerDataForId(state.plotContainers, thisFloor.targetId);
	}, [floorsArray]);

	useEffect(() => {
		const floor = floorsArray.find((item) => {
			return item.targetId == selectedTargetId;
		});
		if (floor) {
			setFloorAvailabilityCount(floor.availabilityCount);
		}
	}, [selectedTargetId]);
	/*
	useEffect(() => {
		if (imageLoaded) getPlotContainerDataForId(state.plotContainers, id);
	}, [imageLoaded]);
*/
	function getPlotContainerDataForId(obj, targetId) {
		obj.forEach((plotContainerData) => {
			if (plotContainerData.id == targetId) {
				setBlockplanImage(plotContainerData.interactiveAssetURI); //

				//let imageUrl = plotContainerData.interactiveAssetURI;
				let interactiveRegions = plotContainerData.interactiveRegions;
				let containerType = plotContainerData.plotContainerType.name;

				setCgiImagePath(plotContainerData.cgiAssetURI);

				if (plotContainerData.name != 'Site Plan') {
					setFloorName(plotContainerData.name);
					setCurrentFloorNumber(plotContainerData.floorNumber);
				}

				if (containerType == 'Block Floor' && plotContainerData.cgiAssetURI) {
					setDisplayCgiImage(true);
				}

				if (containerType == 'Block') {
					setDisplayCgiImage(true);
					setThisBuildingName(plotContainerData.name);

					let tempArray = [];

					const lowerGroundFloor = plotContainerData.plotContainers.find(
						(el) => el.name == 'Lower Ground Floor'
					);

					interactiveRegions.forEach((thisRegion) => {
						let thisFloor = plotContainerData.plotContainers.find(
							(thisX) => thisX.id == thisRegion.targetId
						);

						// Get plots for this floor
						const plots = thisFloor.plotIds.map((id) => getPlotById(state.plots, id));

						// Include only available plots
						const availablePlots = plots.filter(
							(plot) => plot.plotStatusId === getStatusID(state.plotStatuses, 'Available')
						);

						const availabilityCount = availablePlots.length || 0;

						let floorNumber = lowerGroundFloor ? thisFloor.floorNumber - 1 : thisFloor.floorNumber;
						let floorLabel =
							floorNumber < 1
								? floorNumber.toString().replace(/0/, 'G').replace(/-1/, 'LG')
								: floorNumber.toString();

						tempArray.push({
							floorNumber: floorNumber,
							floorLabel: floorLabel,
							floorName: thisFloor.name,
							targetId: thisRegion.targetId,
							availabilityCount: availabilityCount,
						});
					});

					const outputElevationArray = []
						.concat(tempArray)
						.sort((b, a) => parseFloat(a.floorNumber) - parseFloat(b.floorNumber));

					//this is the 1st floor to display
					let startingFloorTargetId =
						outputElevationArray[outputElevationArray.length - 1].targetId;

					setFloorsArray(outputElevationArray);
					getPlotContainerDataForId(plotContainerData.plotContainers, startingFloorTargetId);
				} else {
					if (imageLoaded) {
						buildBlockView(interactiveRegions);
					}
					setSelectedTargetId(targetId); //to enable hi light of elevation marker [rich 24082021]
					return false;
				}
			}
			if (Object.prototype.hasOwnProperty.call(plotContainerData, 'plotContainers')) {
				getPlotContainerDataForId(plotContainerData.plotContainers, targetId);
			}
		});
	}

	function zoomToPlotMarker() {
		if (!isMobile) {
			if (state.selectedPlotMarkerId) {
				let thisMarker = document.getElementById(state.selectedPlotMarkerId);

				if (transformComponentRef.current != null) {
					const { zoomToElement } = transformComponentRef.current;
					let zoomToScale = 2;
					if (scale > 2) zoomToScale = scale;

					zoomToElement(thisMarker, zoomToScale, 3000, 'easeOut');
				}
			}
		}
	}

	function buildBlockView(interactiveRegionsArray) {
		setPlotMarkers([]);

		interactiveRegionsArray.forEach((interactiveRegion) => {
			if (!interactiveRegion.isTargetAContainer) {
				/*
				//show all markers
				let thisPlotInfo = state.plots.find(
					(plotItem) => plotItem.id == interactiveRegion.targetId
				);
				*/

				//show filtered plot markers
				let thisPlotInfo = state.filteredHomes.find(
					(plotItem) => plotItem.id == interactiveRegion.targetId
				);

				if (thisPlotInfo) {
					let thisPlotStatus = state.plotStatuses.find(
						(statusItem) => statusItem.id == thisPlotInfo.plotStatusId
					);

					const coordY = calcImageHeight * interactiveRegion.startY;
					const newPosY = topMargin + coordY;
					const newPosX = calcImageWidth * interactiveRegion.startX;

					const markerWidth = calcImageWidth * interactiveRegion.width;
					const markerHeight = calcImageHeight * interactiveRegion.height;

					const data = {
						x: newPosX,
						y: newPosY,
						width: markerWidth,
						height: markerHeight,
						id: interactiveRegion.targetId,
						plotNumber: thisPlotInfo.plotNumber,
						plotStatus: thisPlotStatus.name,
						colour: thisPlotStatus.color,
					};

					setPlotMarkers((prevArray) => [...prevArray, data]);
				}
			}
		});
	}

	function DisplayElevation(thisTarget) {
		getPlotContainerDataForId(state.plotContainers, thisTarget);
		dispatch({ type: 'closePlotPreview' });
	}

	function ZoomOut() {
		const factor = Math.log((scale - 1.0) / scale);
		transformComponentRef.current.zoomOut(-factor, 300);
		setScale((thisScale) => clamp(thisScale - 1, 1, maxZoomScale()));
	}

	function ZoomIn() {
		const factor = Math.log((scale + 1.0) / scale);
		transformComponentRef.current.zoomIn(factor, 300);
		setScale((thisScale) => clamp(thisScale + 1, 1, maxZoomScale()));
	}

	function CenterView() {
		if (transformComponentRef.current != null) {
			const { centerView } = transformComponentRef.current;
			centerView(1, 300, 'easeOut');
		}
	}

	const StartInteractiveTimeout = () => {
		clearInterval(interactiveTimer);
		interactiveTimer = setInterval(() => {
			EnableMarkerInteraction();
		}, 200);
	};

	function EnableMarkerInteraction() {
		clearInterval(interactiveTimer);
		setIsPanning(false);
	}

	const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

	const FloorBadge = (props) => {
		const { thisFloor } = props;

		const floor = floorsArray.find((item) => {
			return item.floorNumber == thisFloor;
		});

		const b = state.plotStatuses.find((statusItem) => statusItem.name == 'Available');

		return (
			<>
				{' '}
				{floor.availabilityCount > 0 ? (
					<div className='MapControl__badge' style={{ backgroundColor: b.color }} />
				) : (
					''
				)}
			</>
		);
	};

	useEffect(() => {
		setTimeout(() => {
			if (showElevation && floorsRef.current) {
				floorsRef.current.scrollTop = floorsRef.current.scrollHeight;
			}
		}, 100);
	}, [showElevation]);

	return (
		<>
			<Helmet>
				<title>{`Master Plan - ${state.devName.name}`}</title>
			</Helmet>

			<SubMenu>
				<Breadcrumbs />
			</SubMenu>

			{isMounted && (
				<div className='Masterplan' id='masterplanView'>
					<motion.div
						className='Blockplan-info'
						key='blockplan-info'
						animate={{
							opacity: 1,
						}}
						initial={{
							opacity: 0.1,
						}}
						transition={{
							x: { type: 'spring', stiffness: 250, damping: 45 },
						}}
					>
						{thisBuildingName && (
							<div className='Masterplan__Info'>
								{cgiImage && (
									<img className='Masterplan__Info__Image' src={cgiImage} alt={thisBuildingName} />
								)}
								<div className='Masterplan__Info__Content'>
									<div className='Masterplan__Info__Title'>{thisBuildingName}</div>
									{floorName && <div className='Masterplan__Info__Name'>{floorName}</div>}
									{floorAvailabilityCount > 0 ? (
										<AvailabilityBadge count={floorAvailabilityCount} />
									) : (
										<div className='AvailabilityBadge'>0 Available</div>
									)}
								</div>
							</div>
						)}
						{!isMobile && <Locator containerId={id} />}
					</motion.div>
					<motion.div
						className={'Masterplan__Map Masterplan__Map--blockplan'}
						key={'MainWrapper'}
						animate={!isMobile ? { width: `${state.isPreviewPanelOpen ? '70vw' : '100vw'}` } : null}
						onAnimationComplete={state.isPreviewPanelOpen ? zoomToPlotMarker : CenterView}
						transition={{
							x: { type: 'spring', stiffness: 300, damping: 40 },
						}}
					>
						{state.filteredHomes.length <= 0 && (
							<Alert variant='floating'>No homes match the chosen filters.</Alert>
						)}

						<div className='Masterplan__ElevationControl'>
							{showElevation && (
								<motion.div
									style={{ originX: 0.95, originY: 0.95 }}
									initial={{ scale: 0 }}
									animate={{ y: -15, scale: 1 }}
									className='Masterplan__ElevationControl__Buttons'
									ref={floorsRef}
								>
									{floorsArray.map((thisFloor) => {
										let variant = thisFloor.availabilityCount > 0 ? 'default' : 'disabled';
										if (selectedTargetId == thisFloor.targetId) {
											variant = 'secondary';
										}

										return (
											<MapControl
												id={thisFloor.targetId}
												onClick={() => DisplayElevation(`${thisFloor.targetId}`)}
												key={thisFloor.floorNumber.toString()}
												variant={variant}
											>
												{thisFloor.floorLabel}
												<FloorBadge thisFloor={thisFloor.floorNumber} />
											</MapControl>
										);
									})}
								</motion.div>
							)}
							<MapControl
								onClick={() => setShowElevation((current) => !current)}
								variant='layers'
								className={showElevation ? 'active' : ''}
							/>
						</div>

						<TransformWrapper
							ref={transformComponentRef}
							initialScale={scale}
							centerOnInit
							onZoomStop={(e) => {
								setScale(e.state.scale);
							}}
							onPanning={() => {
								setIsPanning(true);
							}}
							onPanningStop={StartInteractiveTimeout}
							onZoom={(e) => {
								setScale(e.state.scale);
							}}
							maxScale={maxZoomScale()}
						>
							{() => (
								<>
									<div className={'Masterplan__Controls Masterplan__Controls--with-elevation'}>
										<MapControl
											variant='zoom-in'
											onClick={ZoomIn}
											title='Zoom in'
											disabled={scale === maxZoomScale()}
										/>
										<MapControl
											variant='zoom-out'
											onClick={ZoomOut}
											title='Zoom out'
											disabled={scale <= 1}
										/>
										<MapControl variant='center-map' onClick={CenterView} title='Center map' />
									</div>
									<TransformComponent
										wrapperStyle={{
											width: '100%',
											height: '100%',
										}}
									>
										<AnimatePresence initial={true} custom={floorDirection} exitBeforeEnter={true}>
											<motion.div
												className='zoomContainer__masterplan'
												style={{ width: `${imageRes.size}px` }}
												key={blockPlanImage}
												custom={floorDirection}
												variants={variants}
												animate='center'
												exit='exit'
												transition={{
													y: { type: 'spring', stiffness: 300, damping: 40 },
												}}
											>
												<img
													ref={imageRef}
													onLoad={() => setImageLoaded(true)}
													style={{ width: `${imageRes.size}px`, height: `${imageRes.size}px` }}
													src={blockPlanImage}
													alt={blockPlanImage}
												/>
												{plotMarkers.map((plotMarker) => (
													<div key={plotMarker.id.toString()}>
														<PlotMarkerBP
															posX={plotMarker.x}
															posY={plotMarker.y}
															markerWidth={plotMarker.width}
															markerHeight={plotMarker.height}
															plotId={plotMarker.id}
															plotNum={plotMarker.plotNumber}
															plotColour={plotMarker.colour}
															floor={currentFloorNumber}
															isPanning={isPanning}
														/>
													</div>
												))}
											</motion.div>
										</AnimatePresence>
									</TransformComponent>
								</>
							)}
						</TransformWrapper>
					</motion.div>
					<PlotPreview />
				</div>
			)}
		</>
	);
}

export default Blockplan;
