import React, { useState } from 'react';

function MapMark(props) {
	const { name } = props;
	const [isActive, setActive] = useState(false);

	const toggleClass = () => {
		setActive(!isActive);
	};

	return (
		<div
			className={'pin bounce development-map-mark' + (isActive ? ' active' : '')}
			style={{ cursor: 'pointer' }}
			onClick={toggleClass}
		>
			<div className='tooltip'>{name}</div>
		</div>
	);
}

export default MapMark;
