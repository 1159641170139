import React, { useContext, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Context } from '../contexts/Store';
import useCheckDevice from '../hooks/useCheckDevice';
import { ReactComponent as Arrow } from '../assets/img/arrow-right.svg';
import '../assets/styles/pages/HybridPlus.scss';

function HybridPlus() {
	const { state, dispatch } = useContext(Context);
	const iFrameRef = useRef(null);

	const { isMobile } = useCheckDevice();

	useEffect(() => {
		dispatch({ type: `setHideHeader`, data: true });
		return () => {
			dispatch({ type: `setHideHeader`, data: false });
		};
	}, [dispatch]);

	const prevButton = () => {
		iFrameRef.current.contentWindow.postMessage('show_previous_slide', '*');
	};

	const nextButton = () => {
		iFrameRef.current.contentWindow.postMessage('show_next_slide', '*');
	};

	const pageMenuButton = () => {
		iFrameRef.current.contentWindow.postMessage('show_page_menu', '*');
	};

	return (
		<>
			<Helmet>
				<title>{`Brochure - ${state.devName.name}`}</title>
			</Helmet>

			<iframe
				ref={iFrameRef}
				className='hybrid-plus__container'
				src={`${state.menuItems.digitalBrochure}?fr=1`}
				width='100%'
				height='100%'
				title='Brochure'
			></iframe>

			<div className='hybrid-plus__menu'>
				<div className='hybrid-plus__menu__item'>
					<div className='hybrid-plus__prev-button' onClick={prevButton}>
						<Arrow />
					</div>
				</div>
				<div className='hybrid-plus__menu__item'>
					<div className='hybrid-plus__page-button' onClick={pageMenuButton}>
						{isMobile ? (
							<>
								<span className='Menu__Burger__line Menu__Burger__line--1'></span>
								<span className='Menu__Burger__line Menu__Burger__line--2'></span>
								<span className='Menu__Burger__line Menu__Burger__line--3'></span>
							</>
						) : (
							<>Contents</>
						)}
					</div>
				</div>
				<div className='hybrid-plus__menu__item'>
					<div className='hybrid-plus__next-button' onClick={nextButton}>
						<Arrow />
					</div>
				</div>
			</div>
		</>
	);
}

export default HybridPlus;
