import React, { useContext, useEffect } from 'react';

import { Fancybox as NativeFancybox } from '@fancyapps/ui/dist/fancybox.esm.js';
import '@fancyapps/ui/dist/fancybox.css';
import '../assets/styles/components/Fancybox.scss';
import { Context } from '../contexts/Store';
import { useLocation } from 'react-router-dom';

function Fancybox(props) {
	const delegate = props.delegate || '[data-fancybox]';
	const { state } = useContext(Context);
	const { pathname } = useLocation();

	useEffect(() => {
		const opts = props.options || {};

		NativeFancybox.bind(delegate, opts);
		return () => {
			NativeFancybox.destroy();

			// if we are on the /favourites page and we remove the item from the list
			// the clean up will be called for all items and the bind will be removed from zoom icon
			// using this condition we'll keep track on fav length arr and pathname and we'll bind again after destroying
			if (state.favPlots.length && pathname === '/favourites') {
				NativeFancybox.bind(delegate, opts);
			}
		};
	}, [state.favPlots, pathname]);

	return <>{props.children}</>;
}

export default Fancybox;
