import React, { useState, useContext } from 'react';
import ActionButton from './ActionButton';
import PlotModal from './PlotModal';
import ToggleButton from './ToggleButton';
import { Context } from '../contexts/Store';

function FloorplanGalleryButton({ floorplans, plotType, display = 'button' }) {
	const { state } = useContext(Context);
	const [showFloorplanModal, setShowFloorplanModal] = useState(false);

	return floorplans.length > 0 ? (
		<>
			{showFloorplanModal && !state.isMobileDevice && (
				<PlotModal
					onClose={() => setShowFloorplanModal(false)}
					floorplans={floorplans}
					plotType={plotType}
				/>
			)}

			{display === 'button' ? (
				<ActionButton icon='floorplan' onClick={() => setShowFloorplanModal(!showFloorplanModal)}>
					Floorplans
				</ActionButton>
			) : (
				<ToggleButton icon='floorplan' onClick={() => setShowFloorplanModal(!showFloorplanModal)} />
			)}
		</>
	) : null;
}

export default FloorplanGalleryButton;
