import React, { useState, useRef, useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { Context } from '../contexts/Store';

import Badge from './Badge';
import Loading from './Loading';
import Checkbox from './Checkbox';
import ToggleButton from './ToggleButton';

function MapFilter({ localAreaData, filterMarkers, getDevelopmentCategoryID }) {
	const { state } = useContext(Context);
	const [showPOICategories, setShowPOICategories] = useState(false);
	const [activeFilters, setActiveFilters] = useState([]);
	const [allCategories, setAllCategories] = useState([]);

	useEffect(() => {
		if (localAreaData) {
			const all = localAreaData.localAreaCategories.map((item) => item.id);
			setAllCategories(all);
			setActiveFilters(all);
		}
	}, [localAreaData]);

	function handleClick(id) {
		let newFilters;

		// Include all categories
		if (id === 'all' && activeFilters !== allCategories) {
			newFilters = allCategories;

			// Remove all categories
		} else if (id === 'all') {
			newFilters = [];

			// Remove active category
		} else if (activeFilters.includes(id)) {
			newFilters = activeFilters.filter((item) => item !== id);

			// Add new category
		} else {
			newFilters = [id, ...activeFilters];
		}

		filterMarkers(newFilters);
		setActiveFilters(newFilters);
	}

	/*
	 * Handle click outside
	 */
	const ref = useRef(null);
	const handleClickOutside = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			setShowPOICategories(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	let animateStyle = { originX: 0.95, originY: 0.95 };

	if (state.isMobileDevice) {
		animateStyle = { originX: 0, originY: 1 };
	}

	return (
		<div className='map-filters-buttons' ref={ref}>
			<AnimatePresence>
				{showPOICategories && (
					<motion.div
						className='POICategories__Container'
						style={animateStyle}
						initial={{ opacity: 0, y: 10, scale: 0.5 }}
						animate={{ opacity: 1, y: -10, scale: 1 }}
						exit={{ opacity: 0, y: 10, scale: 0.5 }}
						transition={{
							duration: 0.25,
							ease: [0.6, 0.01, -0.05, 0.95],
						}}
					>
						<>
							<div className='SinglePOIContainer'>
								<Badge style={{ float: 'right' }} />
								{localAreaData && (
									<Checkbox
										label='All'
										checked={activeFilters.length === allCategories.length ? true : false}
										disabled=''
										id={'all'}
										key='all'
										value=''
										onChange={() => handleClick('all')}
									/>
								)}
							</div>
							{localAreaData ? (
								localAreaData.localAreaCategories.map((item) => {
									//don't show dev in the filter
									if (getDevelopmentCategoryID() !== item.id) {
										return (
											<div className='SinglePOIContainer' key={item.id}>
												<Badge style={{ backgroundColor: item.color, float: 'right' }} />
												<Checkbox
													label={item.name}
													checked={activeFilters.includes(item.id) ? true : false}
													disabled=''
													id={item.id}
													key={item.id}
													value=''
													onChange={() => handleClick(item.id)}
												/>
											</div>
										);
									}
									return null;
								})
							) : (
								<Loading />
							)}
						</>
					</motion.div>
				)}
			</AnimatePresence>
			<ToggleButton
				icon='poi'
				onClick={() => setShowPOICategories(!showPOICategories)}
				size='sm'
				active={showPOICategories}
			>
				Points of Interest
			</ToggleButton>
		</div>
	);
}

export default MapFilter;
