import React, { useContext, useEffect } from 'react';
import { ScrollSync } from 'react-scroll-sync';
import { Context } from '../contexts/Store';

import { getPlotById } from '../helpers/utilities';
import SubMenu from '../components/SubMenu';
import BackButton from '../components/BackButton';
import ToggleButton from '../components/ToggleButton';
import PlotSelector from '../components/PlotSelector';
import CompareCard from '../components/CompareCard';

import '../assets/styles/pages/Compare.scss';

function Compare() {
	const { state, dispatch } = useContext(Context);
	const panels = Array.from({ ...state.comparePlots, length: 2 });

	//enable compare mode on load
	useEffect(() => {
		dispatch({
			type: 'setIsCompareMode',
			data: true,
		});
	}, []);

	return (
		<>
			<ScrollSync proportional={false}>
				<div className='Compare'>
					{panels.map((item, key) => {
						if (!item) {
							return (
								<div className='Compare__Panel' key={key}>
									<PlotSelector />
								</div>
							);
						}

						const plot = getPlotById(state.plots, item);
						if (plot) {
							return (
								<div className='Compare__Panel' key={key}>
									<CompareCard plot={plot} index={key} />
								</div>
							);
						}
					})}
				</div>
			</ScrollSync>

			<SubMenu>
				<BackButton />
				<ToggleButton
					size='sm'
					icon='ruler'
					active={state.compareShowDims}
					onClick={() => dispatch({ type: 'setCompareShowDims' })}
				>
					Dimensions
				</ToggleButton>
			</SubMenu>
		</>
	);
}

export default Compare;
