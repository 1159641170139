import React from 'react';
import { ReactComponent as RotateIcon } from '../assets/img/rotate.svg';
import '../assets/styles/components/spin-prompt.scss';

export function SpinPrompt(props) {
	const { hidden } = props;

	const thisResult = hidden === true ? 'prompt-container--hidden' : '';

	return (
		<>
			<div className={`prompt-container ${thisResult}`}>
				<RotateIcon />
			</div>
		</>
	);
}
