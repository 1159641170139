import React, { useContext } from 'react';
import { Context } from '../contexts/Store';
import { useNavigate } from 'react-router-dom';

function Iframe(props) {
	const { src } = props;

	const { dispatch } = useContext(Context);

	React.useEffect(() => {
		const handleMessage = (event) => {
			if (event.data.source === 'virtual-tour' && event.data.payload.event === 'route') {
				goToRoute(event.data.payload.location);
			} else if (event.data.source === 'virtual-tour' && event.data.payload.event === 'popover') {
				let location = parseInt(event.data.payload.location);
				if (!isNaN(location)) {
					dispatch({
						type: 'showPlotPreview',
						data: { plotid: location },
					});
				}
			} else {
				return null;
			}
		};

		window.addEventListener('message', handleMessage);

		//cleanup
		return () => {
			window.removeEventListener('message', handleMessage);
		};
	});

	const navigate = useNavigate();
	const goToRoute = (location) => navigate(location);

	return (
		<>
			<iframe
				className='full-iframe'
				src={src}
				width='100%'
				height='100%'
				title='Virtual Tour'
			></iframe>
		</>
	);
}

export default Iframe;
