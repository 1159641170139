import { useState, useEffect } from 'react';

//var plotId = 99;
var name = 'none';
//var screenPosX = 50;
//var screenPosY = 50;
//var markerColour = '#ddd'
//var zPos = 1;

function RoadMarker(props) {
	const [divStyle, setDivStyle] = useState();
	//const [plotMarker, setPlotMarker] = useState([]);
	//const  itemName2  = props.itemName
	//button className="potMarkerButton" data-event="popover" data-location="{plotId}" style="z-index: {zPos}; left: {screenPosX}%; top: {screenPosY}%;  background-color: {markerColour};"/>

	//plotId={plotMarkers.id} plotNum={plotMarkers.num} colour={plotMarkers.colour}

	const newDivStyle = {
		top: props.posY + '%',
		left: props.posX + '%',
		zIndex: props.posZ,
	};

	name = props.name;

	useEffect(() => {
		setDivStyle(newDivStyle);
	}, [props.posX, props.posY]);

	//parseInt
	//

	return (
		<button className='roadMarker' style={divStyle}>
			{name}
		</button>
	);
}

export default RoadMarker;
