import React, { useEffect, useState, useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import Axios from 'axios';
import { Helmet } from 'react-helmet';
import { Context } from '../contexts/Store';
import Loading from '../components/Loading';
import MapMark from '../components/MapMark';
import MapMarkDevelopment from '../components/MapMarkDevelopment';
import MapFilter from '../components/MapFilter';
import ToggleButton from '../components/ToggleButton';
//import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"

import '../assets/styles/pages/Map.scss';

import SubMenu from '../components/SubMenu';

// const google = window.google;

function Map() {
	const { state } = useContext(Context);

	let [key, setKey] = useState(0);
	const [localAreaData, setLocalAreaData] = useState();
	const [filteredPlaces, setFilteredPlaces] = useState([]);
	const [showTransit, setShowTransit] = useState([]);
	const [map, setMap] = useState(null);
	const ourRequest = Axios.CancelToken.source();

	const fetchResults = async () => {
		try {
			const response = await Axios.get(`/v1/GetLocalArea/${state.devId}/true`, {
				cancelToken: ourRequest.token,
				headers: { 'Registration-Key': state.devKey },
			});
			if (response.data) {
				//set state
				setLocalAreaData(response.data);
				setFilteredPlaces(response.data.poIs);
				setKey(key + 1); //update key to cause re-render of map

				//set localStorage
				let json = JSON.stringify(response.data);
				localStorage.setItem('localArea', json);
			}
		} catch (e) {
			// console.log('There was a problem or the request was cancelled.');
		}
	};

	//get the location data from api
	useEffect(() => {
		if (!localStorage.getItem('localArea')) {
			fetchResults();
		} else {
			//we have local data

			//set state
			const data = JSON.parse(localStorage.getItem('localArea'));
			setLocalAreaData(data);
			setFilteredPlaces(data.poIs);
		}

		return () => ourRequest.cancel();
	}, [state.devId, state.devKey]);

	useEffect(() => {
		if (map && window.google.maps) {
			const bounds = getMapBounds(map, window.google.maps);
			updateMapBounds(map, window.google.maps, bounds);
		}
	}, [filteredPlaces]);

	//set up Google Maps

	const mapSettings = {
		center: {
			lat: 51.8860942,
			lng: 0.8336077,
		},
		zoom: 5,
	};

	// Return map bounds based on list of places
	const getMapBounds = (map, maps) => {
		const bounds = new maps.LatLngBounds();

		filteredPlaces.forEach((place) => {
			// if(place.categoryId == 1445){
			bounds.extend(new maps.LatLng(place.latitude, place.longitude));
			// }
		});
		return bounds;
	};

	// Re-center map when resizing the window
	const bindResizeListener = (map, maps, bounds) => {
		maps.event.addDomListenerOnce(map, 'idle', () => {
			maps.event.addDomListener(window, 'resize', () => {
				map.fitBounds(bounds);
			});
		});
	};

	// Fit map to its bounds after the api is loaded
	const apiIsLoaded = (map, maps) => {
		//
		setMap(map);

		// Get bounds by our places
		const bounds = getMapBounds(map, maps);

		updateMapBounds(map, maps, bounds);
	};

	const updateMapBounds = (map, maps, bounds) => {
		// Fit map to bounds
		map.fitBounds(bounds);

		// Bind the resize listener
		bindResizeListener(map, maps, bounds);

		// fake scroll
		// so on iphone the tooltip follows pin
		window.scrollTo(window.scrollX, window.scrollY - 1);
		window.scrollTo(window.scrollX, window.scrollY + 1);

		// Set the zoom level if only one pin is left
		if (filteredPlaces.length === 1) {
			map.setZoom(15);
		}
	};

	// set styles for grayscale elements on the map
	function createMapOptions(maps) {
		let mapStylers = [];

		switch (state.mapStyle) {
			case 'googleDefault':
				mapStylers = [...mapStylers];
				break;
			default:
				//Defualt to greyscale
				mapStylers = [...mapStylers, { saturation: -100 }, { gamma: 0.8 }, { lightness: 4 }];
		}

		return {
			panControl: false,
			mapTypeControl: false,
			scrollwheel: false,
			clickableIcons: false,
			fullscreenControl: false,
			zoomControlOptions: {
				position: maps.ControlPosition.RIGHT_BOTTOM,
			},
			styles: [
				{
					featureType: 'administrative',
					stylers: [...mapStylers],
				},
				{
					featureType: 'landscape',
					stylers: [...mapStylers],
				},
				{
					featureType: 'road',
					stylers: [...mapStylers],
				},
				{
					featureType: 'poi',
					elementType: 'labels',
					stylers: [{ visibility: 'off' }],
				},
				{
					featureType: 'poi.business',
					stylers: [{ visibility: 'off' }],
				},
				{
					featureType: 'poi',
					stylers: [...mapStylers],
				},
				{
					featureType: 'water',
					stylers: [...mapStylers],
				},
			],
		};
	}

	//filter markers on id
	function filterMarkers(id) {
		let filtered = [];

		localAreaData.poIs.filter((el) => {
			if (id.includes(el.categoryId) || el.categoryId === getDevelopmentCategoryID()) {
				filtered.push(el);
			}
			return null;
		});

		setFilteredPlaces(filtered);
	}

	//look up the category color from the category list
	function getCategoryColor(id) {
		let currentCategory = localAreaData.localAreaCategories.find((el) => el.id === id);

		let { color } = currentCategory;
		return color;
	}

	function getCategoryName(id) {
		let currentCategory = localAreaData.localAreaCategories.find((el) => el.id === id);

		let { name } = currentCategory;
		return name;
	}

	//get the "Development" category ID so we can display differently
	function getDevelopmentCategoryID() {
		let developmentCategory = localAreaData.localAreaCategories.find(
			(el) => el.name === 'Development'
		);

		let { id } = developmentCategory;
		return id;
	}

	// Determine whether tube lines should be plotted on the map
	// Option is set in runtime-config
	useEffect(() => {
		if (state.showTransitOnMap) {
			setShowTransit(['TransitLayer']);
		}
	}, [state.showTransitOnMap]);

	return (
		<>
			<Helmet>
				<title>{`Local Area - ${state.devName.name}`}</title>
			</Helmet>

			<div className='map-wrap'>
				<SubMenu>
					<div className='SubMenu__Item'>
						<ToggleButton
							icon='tfl'
							onClick={() => setShowTransit(!showTransit)}
							size='sm'
							active={showTransit}
						>
							Transit
						</ToggleButton>
					</div>
					<div className='SubMenu__Item'>
						<MapFilter
							localAreaData={localAreaData}
							filterMarkers={filterMarkers}
							getDevelopmentCategoryID={getDevelopmentCategoryID}
						/>
					</div>
				</SubMenu>

				<GoogleMapReact
					bootstrapURLKeys={{ key: 'AIzaSyB8XKQG2wreOLoUjyXhlmPdwKyAFsFaNUc' }}
					defaultCenter={mapSettings.center}
					defaultZoom={mapSettings.zoom}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
					options={createMapOptions}
					key={key}
					layerTypes={showTransit ? ['TransitLayer'] : []}
				>
					{filteredPlaces ? (
						filteredPlaces.map((place) => {
							if (getDevelopmentCategoryID() !== place.categoryId) {
								getCategoryName(place.categoryId);
								return (
									<MapMark
										key={place.id}
										lat={place.latitude}
										lng={place.longitude}
										color={getCategoryColor(place.categoryId)}
										name={place.name}
										categoryName={getCategoryName(place.categoryId)}
									/>
								);
							}
						})
					) : (
						<Loading />
					)}

					{filteredPlaces
						? filteredPlaces.map((place) => {
								if (getDevelopmentCategoryID() == place.categoryId) {
									return (
										<MapMarkDevelopment
											key={place.id}
											lat={place.latitude}
											lng={place.longitude}
											name={place.name}
										/>
									);
								}
						  })
						: null}
				</GoogleMapReact>
			</div>
		</>
	);
}

export default Map;
