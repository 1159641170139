import React, { useState } from 'react';

import '../assets/styles/components/ExpandButton.scss';
import { ReactComponent as IconArrowDown } from '../assets/img/arrow-down.svg';

function ExpandButton({ onClick }) {
	const [isActive, setIsActive] = useState(false);

	const handleClick = () => {
		if (onClick) {
			onClick();
		}
		setIsActive(!isActive);
	};

	return (
		<button
			className={`ExpandButton ${isActive ? 'ExpandButton--active' : ''}`}
			onClick={handleClick}
		>
			<IconArrowDown />
		</button>
	);
}

export default ExpandButton;
