import { useContext, useEffect } from 'react';

import { Context } from '../contexts/Store';
import useWebSocket from 'react-use-websocket';

function LightServer(props) {
	const { lightServerAddress } = props;

	const { state } = useContext(Context);

	const { sendMessage } = useWebSocket(lightServerAddress, {
		reconnectAttempts: 604800,
		shouldReconnect: () => true,
	});

	useEffect(() => {
		let ID = state.currentLight.ID;
		let status = state.currentLight.status;
		if (status === 'on' && ID) {
			sendMessage(`{"Command": 7, "LightID": ${ID}}`);
		} else if (status === 'off' && ID) {
			sendMessage(`{"Command": 1, "LightID": ${ID}}`);
		} else if (status === 'attractor') {
			sendMessage(`{"Command": 4, "LightID": ${ID}}`);
		}
	}, [state.currentLight, sendMessage]);

	return null;
}

export default LightServer;
