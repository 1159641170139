import React from 'react';
import { useApartmentStatus } from '../hooks/useApartmentStatus';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BuildingIcon } from '../assets/img/building-regular.svg';

function AptMarker(props) {
	const { posX, posY, id, scale, isPanning, tiltAngle } = props;
	const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
	const markerRadius = tiltAngle > 70 ? '100%' : '50% 50% 50% 0';
	const markerSize = clamp(1.5 / scale, 0.25, 1.0);
	const navigate = useNavigate();
	const thisMarkerStatus = useApartmentStatus(id);

	const isFav = thisMarkerStatus.hasFavourite ? 'apt-marker--fav' : '';

	const divStyle = {
		top: posY + '%',
		left: posX + '%',
		borderRadius: markerRadius,
		transform: 'rotate(-45deg) scale(' + markerSize + ')',
		backgroundColor: thisMarkerStatus.colour,
		zIndex: 2,
	};

	return (
		<>
			{thisMarkerStatus.markerVisible ? (
				<div
					className={`apt-marker ${isFav}`}
					style={divStyle}
					onClick={isPanning ? null : () => navigate(`/site/${id}`)}
				>
					<BuildingIcon />
				</div>
			) : (
				''
			)}
		</>
	);
}

export default AptMarker;
