import React, { useState } from 'react';
import { ReactComponent as IconCross } from '../assets/img/cross.svg';

function MapMark(props) {
	const { name, color } = props;
	const [isActive, setActive] = useState(false);

	const toggleClass = () => {
		setActive(!isActive);
	};

	return (
		<div
			className={'marker' + (isActive ? ' active' : '')}
			style={{ backgroundColor: color, cursor: 'pointer' }}
			onClick={toggleClass}
		>
			<div className='tooltip'>
				{name}{' '}
				<span className='tooltip__Icon'>
					<IconCross />
				</span>
			</div>
		</div>
	);
}

export default MapMark;
