import React from 'react';
import { Link } from 'react-router-dom';

import '../assets/styles/components/Button.scss';
import { ReactComponent as IconArrow } from '../assets/img/arrow-right.svg';
import { ReactComponent as IconChevronDown } from '../assets/img/chevron-down.svg';

function Button({ variant = 'primary', size = 'md', to, onClick, className, icon, ...props }) {
	const showButtonIcon = () => {
		switch (icon) {
			case 'arrow':
				return <IconArrow />;

			case 'chevron-down':
				return <IconChevronDown />;

			default:
				throw Error('You need to provide a icon name or the icon name is not valid for the Button');
		}
	};

	return to ? (
		<Link
			to={to}
			role='button'
			className={`Button Button--${variant} Button--${size} ${className}`}
			onClick={onClick}
		>
			<span className='Button__Text'>{props.children}</span>
			{icon && <span className='Button__Icon'>{showButtonIcon()}</span>}
		</Link>
	) : (
		<button className={`Button Button--${variant} Button--${size} ${className}`} onClick={onClick}>
			<span className='Button__Text'>{props.children}</span>
			{icon && <span className='Button__Icon'>{showButtonIcon()}</span>}
		</button>
	);
}

export default Button;
