import React, { forwardRef, Fragment } from 'react';

import '../assets/styles/components/ToggleButton.scss';
import { ReactComponent as IconFilters } from '../assets/img/filter.svg';
import { ReactComponent as IconPOI } from '../assets/img/map-marker-check-light.svg';
import { ReactComponent as IconHeart } from '../assets/img/heart-solid.svg';
import { ReactComponent as IconHeartLight } from '../assets/img/heart-light.svg';
import { ReactComponent as IconClose } from '../assets/img/close.svg';
import { ReactComponent as IconTFL } from '../assets/img/tfl-roundel.svg';
import { ReactComponent as IconArrowLeft } from '../assets/img/long-arrow-left-light.svg';
import { ReactComponent as IconCompare } from '../assets/img/compare.svg';
import { ReactComponent as IconPlus } from '../assets/img/plus.svg';
import { ReactComponent as IconTick } from '../assets/img/tick.svg';
import { ReactComponent as IconRuler } from '../assets/img/ruler-combined-light.svg';
import { ReactComponent as IconFloorplan } from '../assets/img/floorplan-magnify.svg';

const ToggleButton = forwardRef(
	({ variant = 'primary', size = 'md', to, onClick, count, active, icon, children }, ref) => {
		const showIcon = () => {
			switch (icon) {
				case 'filters':
					return <IconFilters />;

				case 'poi':
					return <IconPOI />;

				case 'heart':
					return <IconHeart />;

				case 'heart-light':
					return <IconHeartLight />;

				case 'close':
					return <IconClose />;

				case 'tfl':
					return <IconTFL />;

				case 'arrowLeft':
					return <IconArrowLeft />;

				case 'compare':
					return <IconCompare />;

				case 'plus':
					return <IconPlus />;

				case 'tick':
					return <IconTick />;

				case 'ruler':
					return <IconRuler />;

				case 'floorplan':
					return <IconFloorplan />;
			}
		};

		return (
			!to && (
				<Fragment>
					<button
						className={`ToggleButton ToggleButton--${variant} ToggleButton--${size} ${
							active ? 'ToggleButton--active' : ''
						}`}
						onClick={onClick}
						ref={ref}
					>
						<span className='ToggleButton__Icon'>
							{count >= 0 && <span className='ToggleButton__Count'>{count}</span>}
							{showIcon()}
						</span>
						{children && <span className='ToggleButton__Text'>{children}</span>}
					</button>
				</Fragment>
			)
		);
	}
);

ToggleButton.displayName = 'ToggleButton';

export default ToggleButton;
