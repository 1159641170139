import React, { useContext, useState } from 'react';
import { Context } from '../contexts/Store';

import { useCookies } from 'react-cookie';
import '../assets/styles/pages/Login.scss';

const Login = () => {
	const [inputValue, setInputValue] = useState('');
	const { dispatch } = useContext(Context);
	const [, setCookie] = useCookies(['login']);

	const arrayOfEmails = [
		'richardmfarnell@gmx.com',
		'richard.farnell@thinkbdw.co.uk',
		'test',
		'richard',
	];

	const handleButtonClick = () => {
		if (arrayOfEmails.includes(inputValue)) {
			setCookie('login', 'true', { path: '/', maxAge: 31536000 });
			dispatch({ type: 'setEnableUser', data: false });
		} else {
			setInputValue('');
		}
	};

	return (
		<div className='Login'>
			<div className='Login__container'>
				<p>Please enter a valid email address to continue</p>
				<div className='Login__input-container'>
					<input
						className='Login__input-container--input'
						type='text'
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						placeholder='Enter your email'
					/>
					<div className='Login__input-container--button' onClick={handleButtonClick}>
						Log in
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
