import React, { useContext } from 'react';

import { Context } from '../contexts/Store';

import ToggleButton from './ToggleButton';

import '../assets/styles/components/CompareButton.scss';

function CompareButton({ plotID }) {
	const { state, dispatch } = useContext(Context);

	const active = state.comparePlots.includes(plotID);
	const variant = active ? 'primary' : 'secondary';

	const handleClick = () => {
		dispatch({
			type: 'toggleComparePlot',
			data: plotID,
		});
	};

	return (
		<span className='CompareButton'>
			<ToggleButton
				variant={variant}
				onClick={handleClick}
				icon={active ? 'tick' : 'plus'}
				size='sm'
			>
				{active ? `Added` : `Add Home`}
			</ToggleButton>
		</span>
	);
}

export default CompareButton;
