import React, { useContext } from 'react';
import { Context } from '../contexts/Store';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { ReactComponent as SelectIcon } from '../assets/img/select-arrow.svg';
import '../assets/styles/components/SubMenu.scss';

function SubMenu(props) {
	const { state } = useContext(Context);
	const location = useLocation();

	let offset = 0;
	let showFilter = false;

	const pathArray = location.pathname.split('/');
	pathArray.map((pathItem) => {
		if (state.filterRoutes.includes('/' + pathItem)) {
			showFilter = true;
		}
	});

	// Filters not show on single plot pages
	if (location.pathname.search(/\/[0-9]/) >= 0 && location.pathname.includes('homes')) {
		showFilter = false;
	}

	let showFav = state.favPlots ? state.favPlots.length : 0;

	if (showFilter) offset++;
	if (showFav) offset++;

	let subMenuClassName = offset > 0 ? `SubMenu--offset-${offset}x ` : '';

	return (
		<div className={`SubMenu ${subMenuClassName}`}>
			<div className='SubMenu__Overflow'>
				<div className='SubMenu__Drawer'>{props.children}</div>
			</div>
		</div>
	);
}

export function SubMenuSelect({ list, value, onChange, type }) {
	function getLabel(item) {
		if (item.name) {
			return item.name;
		} else if (item.title) {
			return item.title;
		} else {
			if (type === 'phase') {
				return item.name;
			} else return '';
		}
	}

	return (
		<div className='SubMenu__Item'>
			<div className='SubMenu__Select'>
				<select className='SubMenu__Select__Input' value={value} onChange={onChange}>
					{list
						? list.map((item, index) => (
								<option key={index} value={type === 'phase' ? item.id : index}>
									{getLabel(item)}
								</option>
						  ))
						: null}
				</select>
				<div className='SubMenu__Select__Icon'>
					<SelectIcon />
				</div>
			</div>
		</div>
	);
}

export function SubMenuViewTabs({ views, activeView, setActiveView }) {
	return (
		<div className='SubMenu__Item'>
			<div className='SubMenu__Tabs Tabs'>
				<AnimateSharedLayout>
					<ul id='tabs' className='Tabs__Items'>
						{views.map((item, i) => {
							const isSelected = activeView === item.slug;

							return (
								<motion.li
									key={i}
									className={`Tabs__Item ${isSelected ? 'Tabs__Item--active' : ''}`}
									onClick={() => setActiveView(item.slug)}
									layout
								>
									<span>{item.label}</span>

									{isSelected && (
										<motion.div
											layoutId='Tabs__Bubble'
											className='Tabs__Bubble'
											initial={false}
											animate={{
												backgroundColor: 'var(--theme-primary-color) ',
											}}
										/>
									)}
								</motion.li>
							);
						})}
					</ul>
				</AnimateSharedLayout>
			</div>
		</div>
	);
}

export default SubMenu;
