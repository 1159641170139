import React, { useState, useEffect, useContext } from 'react';
import PlotMarker from '../components/PlotMarker';
import VrMarker from '../components/VrMarker';
import AptMarker from '../components/AptMarker';
import RoadMarker from '../components/RoadMarker';
import { Context } from '../contexts/Store';

function GetSpinFrame(props) {
	//const isTwelveFrame = false;

	const { state } = useContext(Context);
	const [plotMarkers, setPlotMarkers] = useState([]);
	const [vrMarkers, setVrMarkers] = useState([]);
	const [aptMarkers, setAptMarkers] = useState([]);
	const [roadMarkers, setRoadMarkers] = useState([]);
	const [thisSpinData, setThisSpinData] = useState(false);

	const { filteredHomes, spinMarkerData, plotStatuses } = state;
	const { spinAssetId, frameNumber, scale, tilt, showVrMarkers, vrMkrActive, isPanning } = props;

	const frameName = 'FRAME_' + frameNumber;

	useEffect(() => {
		spinMarkerData.every((arrayItem) => {
			if (arrayItem.spinId == spinAssetId) {
				if (props.isTwelveFrame) {
					getMarkersForFrame(arrayItem.spinData[frameName]);
				} else {
					setThisSpinData(arrayItem.spinData);
				}
				return false;
			}
			return true;
		});

		if (thisSpinData && !props.isTwelveFrame) getMarkersForFrame(thisSpinData[frameName]);
	}, [frameNumber, filteredHomes]);

	const getMarkersForFrame = (data) => {
		setPlotMarkers([]);
		setVrMarkers([]);
		setAptMarkers([]);
		setRoadMarkers([]);

		let a = 0;

		for (const thisArrayItem of data) {
			for (let key in thisArrayItem) {
				if (key.includes('plot')) {
					let n = key.indexOf('_');
					let rawPlotNumber = key.substring(n + 1);
					//remove leading zeros
					const plotNumber = rawPlotNumber.replace(/^0+/, '');

					//show all plots
					//const thisPlotInfo = state.plots.find((plotItem) => plotItem.plotNumber == plotNumber);

					//show filtered plots
					const thisPlotInfo = filteredHomes.find((plotItem) => plotItem.plotNumber == plotNumber);

					/*
					const availableColour = plotStatuses.find(
						(statusItem) => statusItem.name == "Available"
					);*/

					//colour value = availableColour.color;

					if (thisPlotInfo != null) {
						const thisPlotStatus = plotStatuses.find(
							(statusItem) => statusItem.id == thisPlotInfo.plotStatusId
						);

						const data = {
							x: (thisArrayItem[key].x / 4000) * 100,
							y: (thisArrayItem[key].y / 4000) * 100,
							z: thisArrayItem[key].dist / 100000,
							num: plotNumber,
							colour: thisPlotStatus.color,
							id: thisPlotInfo.id,
						};
						setPlotMarkers((prevArray) => [...prevArray, data]);
					}
				} else if (key.includes('vr')) {
					const data = {
						x: (thisArrayItem[key].x / 4000) * 100,
						y: (thisArrayItem[key].y / 4000) * 100,
						z: thisArrayItem[key].dist / 100000,
						id: thisArrayItem[key].target,
						key: a,
					};

					a++;

					setVrMarkers((prevArray) => [...prevArray, data]);
				} else if (key.includes('zn')) {
					const data = {
						x: (thisArrayItem[key].x / 4000) * 100,
						y: (thisArrayItem[key].y / 4000) * 100,
						z: thisArrayItem[key].dist / 100000,
						name: thisArrayItem[key].name,
						id: parseInt(thisArrayItem[key].target),
					};
					setAptMarkers((prevArray) => [...prevArray, data]);
				} else if (key.includes('road')) {
					const data = {
						x: (thisArrayItem[key].x / 4000) * 100,
						y: (thisArrayItem[key].y / 4000) * 100,
						z: thisArrayItem[key].dist / 100000,
						name: thisArrayItem[key].road,
					};
					setRoadMarkers((prevArray) => [...prevArray, data]);
				}
			}
		}
	};

	return (
		<>
			{plotMarkers.map((thisPlotMarker) => (
				<div key={thisPlotMarker.id}>
					<PlotMarker
						posX={thisPlotMarker.x}
						posY={thisPlotMarker.y}
						posZ={thisPlotMarker.z}
						plotId={thisPlotMarker.id}
						plotNum={thisPlotMarker.num}
						colour={thisPlotMarker.colour}
						scale={scale}
						tiltAngle={tilt}
						isPanning={isPanning}
					/>
				</div>
			))}
			{showVrMarkers
				? vrMarkers.map((thisVrMarker) => (
						<div key={thisVrMarker.key}>
							<VrMarker
								posX={thisVrMarker.x}
								posY={thisVrMarker.y}
								targId={thisVrMarker.id}
								scale={props.mobile ? scale * 1.5 : scale}
								vrMarkerActive={vrMkrActive}
								hiLightColour={state.devStyle.container.primary.color}
							/>
						</div>
				  ))
				: null}
			{aptMarkers.map((thisAptMarker) => (
				<div key={thisAptMarker.id}>
					<AptMarker
						posX={thisAptMarker.x}
						posY={thisAptMarker.y}
						name={thisAptMarker.name}
						id={thisAptMarker.id}
						scale={scale}
						isPanning={isPanning}
						tiltAngle={tilt}
					/>
				</div>
			))}
			{roadMarkers.map((thisRoadMarker) => (
				<div key={thisRoadMarker.name}>
					<RoadMarker
						posX={thisRoadMarker.x}
						posY={thisRoadMarker.y}
						posZ={thisRoadMarker.z}
						name={thisRoadMarker.name}
					/>
				</div>
			))}
		</>
	);
}

export default GetSpinFrame;
