import React, { useContext, useEffect } from 'react';
import { motion } from 'framer-motion';

import { Context } from '../contexts/Store';
import PlotCard from './PlotCard';
import PlotCardPreview from './PlotCardPreview';
import { SitePlanCardModal } from './Modal';

function PlotPreview() {
	const { state, dispatch } = useContext(Context);

	const selectedPlot = state.selectedPlotId
		? state.plots.find((plot) => state.selectedPlotId === plot.id)
		: null;

	useEffect(() => {
		if (state.isPreviewPanelOpen === true) {
			if (state.selectedPlotId) {
				dispatch({
					type: 'setCurrentLight',
					data: { ID: selectedPlot.lightId, status: 'on' },
				});
			}
		}
		return () => {
			dispatch({
				type: 'turnOffCurrentLight',
			});
		};
	}, [state.isPreviewPanelOpen, state.selectedPlotId]);

	return (
		state.isPreviewPanelOpen &&
		state.selectedPlotId && (
			<motion.div
				className='PlotPreview'
				key='plot-preview-panel'
				animate={{
					opacity: state.isPreviewPanelOpen ? 1.0 : 0.0,
				}}
				initial={{
					opacity: 0.1,
				}}
				transition={{
					x: { type: 'spring', stiffness: 250, damping: 45 },
				}}
			>
				{state.isTabletDevice ? (
					<SitePlanCardModal onClose={() => dispatch({ type: 'closePlotPreview' })}>
						<div className='PlotPreview__Modal'>
							<PlotCard item={selectedPlot} close={() => dispatch({ type: 'closePlotPreview' })} />
						</div>
					</SitePlanCardModal>
				) : (
					<PlotCardPreview
						item={selectedPlot}
						close={() => dispatch({ type: 'closePlotPreview' })}
					/>
				)}
			</motion.div>
		)
	);
}

export default PlotPreview;
