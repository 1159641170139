import React, { useContext, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { Context } from '../contexts/Store';
import { resetAllFilters, resetFilter } from '../contexts/Filters';

import Filter from './Filter';
import ToggleButton from './ToggleButton';
import Icon from './Icon';

import '../assets/styles/components/GlobalFilters.scss';
import { ReactComponent as IconTickCircle } from '../assets/img/tick-circle.svg';
import { ReactComponent as IconChevronDown } from '../assets/img/chevron-down.svg';
import { ReactComponent as IconReset } from '../assets/img/reset.svg';
import useDropdown from '../hooks/useDropdown';

function GlobalFilters() {
	const { state, dispatch } = useContext(Context);

	//filters menu
	const [isFiltersOpen, setIsFiltersOpen] = useState(false);
	const buttonRef = useRef(null);
	const containerRef = useRef(null);
	useDropdown(buttonRef, containerRef, setIsFiltersOpen);

	const [activeFilter, setActiveFilter] = useState(0);
	let animateStyle = { originX: 0.95, originY: 0.95 };

	const countActiveFilters = () => {
		return state.filters.filter((item) => item.active).length;
	};

	const handleResetAll = () => {
		resetAllFilters(state, dispatch);
		setActiveFilter(0);
	};

	if (state.isMobileDevice) {
		animateStyle = { originX: 0, originY: 1 };
	}

	return (
		<div className='GlobalFilters'>
			<AnimatePresence>
				{isFiltersOpen && (
					<motion.div
						className='GlobalFilters__Filters'
						style={animateStyle}
						initial={{ opacity: 0, y: 10, scale: 0.5 }}
						animate={{ opacity: 1, y: -5, scale: 1 }}
						exit={{ opacity: 0, y: 10, scale: 0.5 }}
						transition={{
							duration: 0.25,
							ease: [0.6, 0.01, -0.05, 0.95],
						}}
						ref={containerRef}
					>
						{state.filters.map((item, i) => {
							let buttonClassName = activeFilter === i ? 'GlobalFilters__Toggle--active ' : '';
							buttonClassName += item.name === 'plotStatus' ? 'GlobalFilters__Toggle--fade ' : '';

							return (
								item.values.length > 0 && (
									<div
										className={`GlobalFilters__Filter GlobalFilters__Filter--${item.name} ${
											item.active ? 'GlobalFilters__Filter--active' : ''
										}`}
										key={i}
									>
										<button
											className={`GlobalFilters__Toggle ${buttonClassName}`}
											onClick={() => setActiveFilter(i)}
										>
											{item.displayName !== 'Status' && (
												<span
													className={`GlobalFilters__Toggle__Icon GlobalFilters__Toggle__Icon--${item.name}`}
												>
													<Icon icon={item.displayName} />
												</span>
											)}
											{item.displayName}
											{item.active && <IconTickCircle className='GlobalFilters__Toggle__Tick' />}
											<IconChevronDown className='GlobalFilters__Toggle__Chevron' />
										</button>
										{activeFilter === i && (
											<div className='GlobalFilters__Filter__Items'>
												<div className='GlobalFilters__Filter__Items__Inner'>
													<Filter filter={item} index={i} key={i} />
													{item.active && item.name !== 'plotStatus' && (
														<button
															className='GlobalFilters__Filter__Reset'
															onClick={() => resetFilter(state, dispatch, i)}
														>
															<IconReset />
															Reset
														</button>
													)}
												</div>
											</div>
										)}
									</div>
								)
							);
						})}
						{countActiveFilters() > 1 && (
							<button className='GlobalFilters__ResetAll' onClick={() => handleResetAll()}>
								<IconReset />
								Reset All
							</button>
						)}
					</motion.div>
				)}
			</AnimatePresence>

			<ToggleButton
				ref={buttonRef}
				icon='filters'
				onClick={() => setIsFiltersOpen((prev) => !prev)}
				count={countActiveFilters()}
			/>
		</div>
	);
}

export default GlobalFilters;
