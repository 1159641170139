import { useContext } from 'react';
import { Context } from '../contexts/Store';

export function TotalArea(type) {
	const { state } = useContext(Context);

	if (type.metricArea === '' && type.imperialArea === '') return;

	if (type.metricArea === '') return type.imperialArea + ' sq ft';

	return state.metricSystem ? type.metricArea + ' m' : type.imperialArea + ' sq ft';
}

export function enableButton(type) {
	return type.imperialArea !== '' && type.metricArea !== '';
}
