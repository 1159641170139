import React from 'react';
import { motion } from 'framer-motion';

import '../assets/styles/components/FloorSelector.scss';

function FloorSelector({ floors, activeFloor, setActiveFloor }) {
	return (
		<div className='FloorSelector'>
			{floors
				.sort((floorA, floorB) => {
					floorA.order - floorB.order;
				})
				.map((floor, i) => {
					return (
						<button className={`FloorSelector__Floor`} key={i} onClick={() => setActiveFloor(i)}>
							{activeFloor === i ? (
								<motion.div
									className='FloorSelector__FloorNumber'
									initial={{
										backgroundColor: 'var(--menu-secondary-color)',
									}}
									animate={{
										backgroundColor: 'var(--theme-primary-color)',
									}}
								>
									{floor.label}
								</motion.div>
							) : (
								<div className='FloorSelector__FloorNumber'>{floor.label}</div>
							)}

							<div className='FloorSelector__FloorName'>
								{floor.caption}

								{activeFloor === i && (
									<motion.div
										layoutId='FloorSelector__FloorName__Bubble'
										className='FloorSelector__FloorName__Bubble'
										initial={false}
										animate={{
											backgroundColor: 'var(--menu-secondary-color)',
											opacity: 0.2,
										}}
									/>
								)}
							</div>
						</button>
					);
				})}
		</div>
	);
}

export default FloorSelector;
