import React, { useContext } from 'react';
import { Context } from '../contexts/Store';

function PlotMarker(props) {
	const { state, dispatch } = useContext(Context);
	const { selectedPlotId, isPreviewPanelOpen } = state;
	const { posX, posY, posZ, plotId, plotNum, colour, scale, tiltAngle, isPanning } = props;
	const markerRadius = tiltAngle > 70 ? '100%' : '50% 50% 50% 0';
	const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

	const markerSize = clamp(1.5 / scale, 0.25, 1.0);

	const divStyle = {
		top: `calc(${posY}% - ${9}px)`,
		left: posX + '%',
		zIndex: posZ,
		backgroundColor: colour,
		borderRadius: markerRadius,
		transform: 'rotate(-45deg) scale(' + markerSize + ')',
		padding: plotNum.length > 3 ? '0.8em' : '0',
	};

	const PlotMarkerClicked = () => {
		if (isPanning) return;
		dispatch({
			type: 'showPlotPreview',
			data: {
				plotid: plotId,
				markerId: plotNum,
			},
		});
	};

	const getSelectedPlotId =
		selectedPlotId === plotId && isPreviewPanelOpen ? 'plot-marker--active' : '';

	const isFav = state.favPlots ? (state.favPlots.includes(plotId) ? 'plot-marker--fav' : '') : null;

	return (
		<div
			key={plotNum}
			id={plotNum}
			className={`plot-marker  ${getSelectedPlotId} ${isFav}`}
			onClick={PlotMarkerClicked}
			style={divStyle}
		>
			<div className='plot-marker__text'>{plotNum}</div>
		</div>
	);
}

export default PlotMarker;
