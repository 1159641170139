import React, { useEffect, useContext, useState } from 'react';
import Iframe from '../components/Iframe';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import { Context } from '../contexts/Store';

import PlotPreview from '../components/PlotPreview';
import SubMenu, { SubMenuSelect } from '../components/SubMenu';
import BackButton from '../components/BackButton';

import '../assets/styles/pages/ExteriorTour.scss';

function ExteriorTour() {
	const { state, dispatch } = useContext(Context);
	const navigate = useNavigate();
	const goToRoute = (location) => navigate(location);

	const { phase, id } = useParams();
	const location = useLocation();
	const previousPath = location.state ? location.state.previousPath : '';

	if (id) {
		var _phase = location.pathname.substring(
			location.pathname.lastIndexOf('/'),
			location.pathname.indexOf('/') + 1
		);

		_phase = _phase.replace('exterior-tour/', '');

		if (_phase !== 'current' && state.selectedPhaseName !== '') {
			if (_phase !== state.selectedPhaseName) {
				goToRoute(`/exterior-tour/${state.selectedPhaseName}/${id}`);
			}
		}
	}

	//get the items data from the menu
	const tours = state.menuItems.exteriorTours;
	const [currentItem, setCurrentItem] = useState(false);
	const [currentItemKey, setCurrentItemKey] = useState(0);

	function getCurrentItem(itemSlug, items) {
		let item = null;
		let itemKey = null;

		if (items && items.length > 0) {
			if (itemSlug) {
				items.find((el, key) => {
					if (el.slug === itemSlug) {
						itemKey = key;
						item = el;
						return;
					}
				});

				if (item) {
					//
				} else {
					item = items[0]; //default to first
					itemKey = 0;
				}
			} else {
				item = items[0]; //default to first
				itemKey = 0;
			}
		}
		return { itemKey, item };
	}

	useEffect(() => {
		const { itemKey, item } = getCurrentItem(phase, tours);
		setCurrentItem(item);
		setCurrentItemKey(itemKey);
	}, [phase, tours]);

	// Close plotPreview on first load
	useEffect(() => {
		dispatch({
			type: 'closePlotPreview',
		});
	}, []);

	function changeTour(key) {
		let selected = state.menuItems.exteriorTours[key];
		goToRoute(`/exterior-tour/${selected.slug}`);
	}

	const mapMargin = state.isMobileDevice ? '2rem' : '4rem';

	// const changeSiteplan = (phaseId) => {
	// 	setPhaseId(phaseId);
	// };

	return (
		<div className='ExteriorTour'>
			<Helmet>
				<title>{`Exterior Tour - ${state.devName.name}`}</title>
			</Helmet>

			{((state.menuItems.exteriorTours && state.menuItems.exteriorTours.length > 1) ||
				previousPath.includes('/site')) && (
				<SubMenu>
					{previousPath.includes('/site') && <BackButton />}
					{state.menuItems.exteriorTours && state.menuItems.exteriorTours.length > 1 && (
						<SubMenuSelect
							list={state.menuItems.exteriorTours}
							value={currentItemKey}
							onChange={(e) => changeTour(e.target.value)}
						></SubMenuSelect>
					)}
				</SubMenu>
			)}

			{currentItem ? (
				<motion.div
					id='virtualtour'
					className='Masterplan__Map'
					key={'MainWrapper'}
					animate={{
						width:
							state.isPreviewPanelOpen && !state.isTabletDevice
								? `calc(70vw - ${mapMargin})`
								: `calc(100vw - ${mapMargin})`,
					}}
					transition={{
						x: { type: 'spring', stiffness: 300, damping: 40 },
					}}
				>
					<Iframe src={!id ? currentItem.url : `${currentItem.url}?startscene=pano${id}`}></Iframe>
				</motion.div>
			) : null}

			<PlotPreview />

			{/* <div className='sub-menu'>
				<div className='container'>
					{spinConfigData && spinConfigData.length > 1 ? (
						<SubMenu>
							<SubMenuSelect
								list={plotContainers}
								value={phaseId}
								onChange={(e) => changeSiteplan(e.target.value)}
								type='phase'
							></SubMenuSelect>
						</SubMenu>
					) : null}
				</div>
			</div> */}
		</div>
	);
}

export default ExteriorTour;
