import React from 'react';

import { EqualHeightElement } from 'react-equal-height';

function CompareRow({ name, label = true, children }) {
	const cssClassName = name ? name.toLowerCase().replace(' ', '-') : '';

	return (
		<EqualHeightElement name={name}>
			<div className={`CompareItem CompareItem--${cssClassName}`}>
				{label && <h3 className='CompareItem__Label'>{name}</h3>}

				<div className='CompareItem__Item'>{children}</div>
			</div>
		</EqualHeightElement>
	);
}

export default CompareRow;
