import React from 'react';
import { formatCustomFields } from '../helpers/utilities';
import Icon from './Icon';

function CustomFields({ fields }) {
	fields = formatCustomFields(fields);
	return (
		<div className='CustomFields'>
			{fields.map((field) => {
				return (
					<div className='CustomFields__Field' key={field.name}>
						<Icon icon={field.name} />
						{field.value}
					</div>
				);
			})}
		</div>
	);
}

export default CustomFields;
