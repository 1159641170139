import React, { useContext } from 'react';
import { Context } from '../contexts/Store';

import '../assets/styles/components/Meta.scss';

import { ReactComponent as IconBeds } from '../assets/img/beds.svg';
import { ReactComponent as IconArea } from '../assets/img/ruler.svg';
import { ReactComponent as IconPlot } from '../assets/img/house.svg';
import { ReactComponent as IconBuilding } from '../assets/img/building-meta.svg';
import { ReactComponent as IconFloor } from '../assets/img/levels.svg';

//display = bigRow (on types), row, list

function Meta({ beds, area, type, floorData, display = 'bigRow', enableButton }) {
	const { state, dispatch } = useContext(Context);
	const { metricSystem } = state;

	return (
		<ul className={`Meta Meta--${display}`}>
			{beds && (
				<li>
					<span className='Meta__Icon'>
						<IconBeds />
					</span>
					<span className='Meta__Label'>
						{beds} {parseInt(beds) > 1 ? 'beds' : 'bed'}
					</span>
				</li>
			)}
			{area && (
				<li
					onClick={
						enableButton ? () => dispatch({ type: 'setMetricSystem', data: !metricSystem }) : null
					}
				>
					<span className='Meta__Icon'>
						<IconArea />
					</span>
					<span className='Meta__Label'>{area}</span>
				</li>
			)}
			{type && (
				<li>
					<span className='Meta__Icon'>
						<IconPlot />
					</span>
					<span className='Meta__Label'>{type}</span>
				</li>
			)}
			{floorData?.blockName && (
				<li>
					<span className='Meta__Icon'>
						<IconBuilding />
					</span>
					<span className='Meta__Label'>{floorData.blockName}</span>
				</li>
			)}
			{floorData?.floorName && (
				<li>
					<span className='Meta__Icon'>
						<IconFloor />
					</span>
					<span className='Meta__Label'>{floorData.floorName}</span>
				</li>
			)}
		</ul>
	);
}

export default Meta;
