import React, { useContext } from 'react';
import { Context } from '../contexts/Store';

import '../assets/styles/components/SortButton.scss';
import { ReactComponent as IconSort } from '../assets/img/sort.svg';

function SortButton({ sortKey, ...props }) {
	const { state, dispatch } = useContext(Context);

	const handleClick = () => {
		if (state.homesSortKey !== sortKey) {
			dispatch({
				type: 'setHomesSortKey',
				data: sortKey,
			});
		} else {
			dispatch({
				type: 'setHomesSortOrder',
			});
		}
	};

	const active = state.homesSortKey === sortKey ? 'active' : '';

	return (
		<div
			className={`SortButton SortButton--${state.homesSortOrder} SortButton--${active}`}
			onClick={handleClick}
		>
			{props.children}
			<span className={`SortButton__Icon SortButton__Icon--${active}`}>
				{state.homesSortKey === sortKey && <IconSort />}
			</span>
		</div>
	);
}

export default SortButton;
