import React from 'react';
import { useNavigate } from 'react-router-dom';
import useCheckDevice from '../hooks/useCheckDevice';
import { useApartmentStatus } from '../hooks/useApartmentStatus';
import { FavIcon } from '../components/FavIcon.jsx';
import { ReactComponent as BuildingIcon } from '../assets/img/building-regular.svg';

function AptMarkerMP(props) {
	const { posX, posY, markerWidth, markerHeight, id, isPanning } = props;
	const navigate = useNavigate();
	const { isMobile } = useCheckDevice();
	const padding = () => {
		return isMobile ? 4 : 3;
	};

	const paddingPixels = markerWidth / padding();
	const thisMarkerStatus = useApartmentStatus(id);

	const isFav = thisMarkerStatus.hasFavourite ? <FavIcon /> : null;

	let divStyle = {
		top: parseInt(posY - markerHeight / 2) + 'px',
		left: parseInt(posX - markerHeight / 2) + 'px',
		width: parseInt(markerWidth * 2) + 'px',
		height: parseInt(markerHeight * 2) + 'px',
		backgroundColor: thisMarkerStatus.colour,
		padding: parseInt(paddingPixels) + 'px',
	};

	divStyle.transform = 'scale(0.5)';

	return (
		<>
			{thisMarkerStatus.markerVisible ? (
				<div
					className='apt-marker-mp'
					style={divStyle}
					onClick={isPanning ? null : () => navigate(`/site/${id}`)}
				>
					<BuildingIcon />
					{isFav}
				</div>
			) : (
				''
			)}
		</>
	);
}

export default AptMarkerMP;
