import React, { useState } from 'react';

import '../assets/styles/components/Dims.scss';

function Dims({ data, compareCard }) {
	let dims = Object.assign([], data.dimensions);
	const [thisClassName] = useState(`${compareCard ? 'DimsCompare' : 'Dims'}`);

	if (data.imperialArea !== '' && data.metricArea !== '')
		dims.push({
			id: 'ta',
			name: 'Total Area',
			imperialDimensions: data.imperialArea + ' sq ft',
			metricDimensions: data.metricArea + ' sq m',
		});

	return (
		<table className={`${thisClassName}`}>
			<tbody>
				{dims.map((item) => {
					return (
						<tr className={`${thisClassName}__Room`} key={item.id}>
							<td className={`${thisClassName}__Room__Item`}>
								<strong>{item.name}</strong>
							</td>
							<td className={`${thisClassName}__Room__Item`}>{item.imperialDimensions}</td>
							<td className={`${thisClassName}__Room__Item`}>{item.metricDimensions}</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

export default Dims;
