import React from 'react';
import { useNavigate } from 'react-router-dom';

import ToggleButton from '../components/ToggleButton';

function BackButton(props) {
	const navigate = useNavigate();
	const onClick = props.onClick || (() => navigate(-1));

	return (
		<ToggleButton icon='arrowLeft' onClick={onClick} size='sm'>
			Back
		</ToggleButton>
	);
}

export default BackButton;
