import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { EqualHeight } from 'react-equal-height';

import { Context } from '../contexts/Store';

import PlotCard from '../components/PlotCard';
import PlotDetailCard from '../components/PlotDetailCard';
import Alert from '../components/Alert';
import Button from '../components/Button';
import SubMenu, { SubMenuViewTabs } from '../components/SubMenu';
import LoginForm from '../components/LoginForm';
import FavouritesQRCode from '../components/FavouritesQRCode';
import CompareToggle from '../components/CompareToggle';
import CompareComboBar from '../components/CompareComboBar';

import '../assets/styles/pages/Favourites.scss';
import { ReactComponent as IconTrash } from '../assets/img/trash.svg';

function Favourites() {
	const { state, dispatch } = useContext(Context);
	const updatedFavPlots = state.plots.filter((plot) => state.favPlots.includes(plot.id));

	const [cookies] = useCookies(['inMarketingSuite']);
	const [inMarketingSuite] = useState(cookies.inMarketingSuite === 'true' ? true : false);

	const [activeView, setActiveView] = useState('grid');
	const views = [
		{
			label: 'Grid',
			slug: 'grid',
		},
		{
			label: 'Detail',
			slug: 'detail',
		},
	];

	const clearAllFavourites = () => {
		dispatch({
			type: 'clearFavPlots',
		});
	};

	return (
		<div className='Homes'>
			<Helmet>
				<title>{`Favourites - ${state.devName.name}`}</title>
			</Helmet>

			<SubMenu>
				{activeView === 'grid' && state.favPlots.length >= 2 && <CompareToggle size='sm' />}
				<SubMenuViewTabs
					views={views}
					activeView={activeView}
					setActiveView={setActiveView}
				></SubMenuViewTabs>
			</SubMenu>

			{state.isCompareMode && activeView === 'grid' && <CompareComboBar />}

			{state.favPlots.length ? (
				<>
					{activeView === 'grid' && (
						<div className='container'>
							<div className='Plot__Grid'>
								{updatedFavPlots.map((plot) => (
									<PlotCard item={plot} key={plot.id} />
								))}
							</div>
						</div>
					)}

					{activeView === 'detail' && (
						<div
							className={`Detail__Grid ${
								state.favPlots.length < 4 ? 'Detail__Grid--centered' : ''
							}`}
						>
							<EqualHeight>
								{updatedFavPlots.map((plot) => (
									<PlotDetailCard item={plot} key={plot.id} />
								))}
							</EqualHeight>
						</div>
					)}
				</>
			) : (
				<Alert>
					No favourites found. Visit the <Link to='/homes'>Homes</Link> page to add some.
				</Alert>
			)}

			<section className='Favourites__Footer'>
				{state.showLogin && (
					<>
						{!inMarketingSuite ? (
							<div className='Favourites__Login'>
								<LoginForm />
							</div>
						) : state.favPlots.length > 0 ? (
							<div className='Favourites__Sync'>
								<FavouritesQRCode />
							</div>
						) : null}
					</>
				)}
				<div className='Favourites__Clear'>
					{state.favPlots.length > 0 && (
						<Button
							onClick={clearAllFavourites}
							variant='light'
							size='sm'
							className='Button--trash'
						>
							<IconTrash />
							Clear all favourites
						</Button>
					)}
				</div>
			</section>
		</div>
	);
}

export default Favourites;
