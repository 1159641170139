import React from 'react';

import { ReactComponent as IconPrice } from '../assets/img/price.svg';
import { ReactComponent as IconBeds } from '../assets/img/beds-small.svg';
import { ReactComponent as IconHouse } from '../assets/img/house-small.svg';
import { ReactComponent as IconHTB } from '../assets/img/help-to-buy.svg';
import { ReactComponent as IconCompass } from '../assets/img/compass.svg';
import { ReactComponent as IconCar } from '../assets/img/car.svg';
import { ReactComponent as IconBuilding } from '../assets/img/building.svg';
import { ReactComponent as IconFloors } from '../assets/img/levels.svg';
import { ReactComponent as IconDefault } from '../assets/img/help-to-buy.svg';
import { ReactComponent as IconSort } from '../assets/img/sort-arrows.svg';

function Icon({ icon }) {
	switch (icon) {
		case 'Price Range':
		case 'Price':
			return <IconPrice />;

		case 'Beds':
		case 'Bedrooms':
		case 'Number of beds':
			return <IconBeds />;

		case 'Property Type':
		case 'Type':
			return <IconHouse />;

		case 'Help to Buy':
			return <IconHTB />;

		case 'Aspect':
			return <IconCompass />;

		case 'Parking':
			return <IconCar />;

		case 'Floor':
		case 'Floors':
			return <IconFloors />;

		case 'Building':
			return <IconBuilding />;

		case 'Sort':
		case 'sort':
			return <IconSort />;

		default:
			return <IconDefault />;
	}
}

export default Icon;
