import React, { useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { post, getNoHeaders, xhrDelete } from './adapters/xhr';
import { Context } from './contexts/Store';
import { setupFilters, runFilters } from './contexts/Filters';
import { sortHomes } from './helpers/sortHomes';

import getThinkHubData from './helpers/useThinkHubData';
import plotsToTypes from './helpers/plotsToTypes';
import parseMenuItems from './helpers/parseMenuItems';

import AppRoutes from './pages/AppRoutes';

import Theme from './components/Theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Spinner from './components/LoadingSpinner';
import IdleTimerContainer from './components/IdleTimerContainer';
import LightServer from './components/LightServer';
import CheckDevice from './components/CheckDevice';

import './assets/styles/style.scss';
import LogRocket from 'logrocket';

import Login from './pages/Login';

const App = () => {
	const { state, dispatch } = useContext(Context);

	const [cookies] = useCookies(['inMarketingSuite', 'haveLights', 'lightServerAddress', 'login']);

	useEffect(() => {
		if (cookies.login !== undefined) {
			const value = cookies.login === 'true' ? true : false;
			const invertedValue = !value;
			dispatch({ type: 'setEnableUser', data: invertedValue });
		}
	}, [cookies.login]);

	//const devSpinPath = window['runConfig'].devSpinPath;
	//const devId = window['runConfig'].devId;

	//check for webp support
	async function supportsWebp() {
		if (!self.createImageBitmap) return false;
		const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
		const blob = await fetch(webpData).then((r) => r.blob());
		return createImageBitmap(blob).then(
			() => true,
			() => false
		);
	}

	useEffect(() => {
		(async () => {
			if (await supportsWebp())
				dispatch({
					type: 'setSupportsWebp',
				});
		})();
	}, []);

	getThinkHubData();

	//get plot VR data
	useEffect(() => {
		if (state.allLoaded && state.menuItems && state.menuItems.plotVRs) {
			getNoHeaders(state.menuItems.plotVRs).then((response) => {
				if (response.data) {
					dispatch({
						type: 'SET_PLOT_VRS',
						data: response.data,
					});
				}
			});
		}
	}, [state.appLoaded, state.menuItems]);

	useEffect(() => {
		if (state.devSpinPath !== '') {
			getNoHeaders(`${state.devSpinPath}${state.devId}/spin.json`).then((response) => {
				if (response.data) {
					dispatch({
						type: 'setGlobalSpinConfigData',
						data: response.data,
					});
				}
			});
		}
	}, []);

	useEffect(() => {
		if (state.globalSpinConfigData !== false) {
			state.globalSpinConfigData.forEach((thisSpin) => {
				getNoHeaders(`${state.devSpinPath}${state.devId}/${thisSpin.id}/spinExport.json`).then(
					(response) => {
						if (response.data) {
							const tempObj = { spinId: thisSpin.id, spinData: response.data };
							dispatch({
								type: 'setSpinMarkerData',
								data: tempObj,
							});
						}
					}
				);
				getNoHeaders(`${state.devSpinPath}${state.devId}/${thisSpin.id}/spinConfig.json`).then(
					(response) => {
						if (response.data) {
							const tempObj = { spinId: thisSpin.id, spinConfigData: response.data };
							dispatch({
								type: 'setSpinConfigData',
								data: tempObj,
							});
						}
					}
				);
			});
		}
	}, [state.globalSpinConfigData]);

	//parseMenuItems
	useEffect(() => {
		if (state.allLoaded) {
			let menuItemsRaw = parseMenuItems(state.menuItemsRaw);
			dispatch({ type: `SET_MENU_DATA`, payload: menuItemsRaw });
		}
	}, [state.allLoaded]);

	//parseDevelopmentPhases
	useEffect(() => {
		if (state.allLoaded) {
			let devPhasesRaw = state.developmentPhases;
			dispatch({ type: `SET_DEVELOPMENT_PHASES`, payload: devPhasesRaw });
		}
	}, [state.allLoaded]);

	//gallery Data
	useEffect(() => {
		if (state.allLoaded) {
			const galleryData = state.galleryData.filter((node) => node.name !== 'Interior-Tour');
			const interiorTourData = state.galleryData.filter((node) => node.name === 'Interior-Tour');

			dispatch({ type: `SET_INTERIOR_TOUR_DATA`, payload: interiorTourData });
			dispatch({ type: `SET_GALLERY_DATA`, payload: galleryData });
		}
	}, [state.allLoaded]);

	//Transform plots to types
	useEffect(() => {
		if (state.allLoaded) {
			let types = plotsToTypes(state.plots, state.plotStatuses);
			dispatch({ type: `SET_TYPES_DATA`, payload: types });
		}
	}, [state.allLoaded]);

	//Set up the filters
	useEffect(() => {
		if (state.allLoaded) {
			setupFilters(state, dispatch);
		}
	}, [state.allLoaded]);

	//Add block and floor data to plots
	useEffect(() => {
		if (state.plotContainers) {
			//loop each plot
			const plots = state.plots.map((currentPlot) => {
				//get the plot containers
				let data = state.plotContainers[0];

				let sites = '';
				if (data.plotContainerType.name !== 'Site') {
					sites = state.plotContainers[0].plotContainers;
				} else {
					sites = state.plotContainers;
				}

				let matchedFloor = false;

				sites.forEach((site) => {
					let blocks = site.plotContainers;

					blocks.forEach((block) => {
						let floors = block.plotContainers;

						floors.forEach((floor) => {
							if (floor.plotIds.includes(currentPlot.id)) {
								let plots = floor.interactiveRegions;
								plots.forEach((plot) => {
									if (plot.targetId === currentPlot.id) {
										// Found a matching floor
										matchedFloor = {
											blockName: block.name,
											floorName: floor.name,
											blockId: block.id,
											floorNumber: floor.floorNumber + 1,
											imgURL: floor.interactiveAssetURI,
											x: plot.startX,
											y: plot.startY,
										};
									}
								});
							}
						});
					});
				});
				//return the matched floor
				return { ...currentPlot, floor: matchedFloor?.floorNumber, floorData: matchedFloor };
			});
			dispatch({
				type: `SET_PLOTS_DATA`,
				payload: plots,
			});
		}
	}, [state.plotContainers]);

	//run filters
	useEffect(() => {
		if (state.availableHomes) {
			runFilters(state, dispatch);
		}
	}, [state.availableHomes, state.filters]);

	//sort plots
	useEffect(() => {
		if (state.filteredHomes.length && state.allLoaded) {
			const sortedHomes = sortHomes(state.filteredHomes, state.homesSortKey, state.homesSortOrder);
			dispatch({
				type: 'setFilteredHomes',
				data: sortedHomes,
			});
		}
	}, [state.allLoaded, state.homesSortKey, state.homesSortOrder]);

	//load favs
	useEffect(() => {
		localStorage.setItem('favPlots', JSON.stringify(state.favPlots));
	}, [state.favPlots]);

	//update remote fav plots
	useEffect(() => {
		async function addRemoteFavPlots() {
			try {
				await post(`/v2/AddPlotsToCustomerFavourites/`, {
					customerEmail: state.user.email,
					PlotIds: state.favPlotsToAdd,
				});

				dispatch({ type: 'remotePlotAdded' });
			} catch (error) {
				// console.log(error.response);
			}
		}

		if (state.loggedIn && state.favPlotsToAdd.length > 0) {
			addRemoteFavPlots();
		}
	}, [state.favRemoteUpdate]);

	//remove fav plot from remote
	useEffect(() => {
		async function removeRemoteFavPlots() {
			try {
				await xhrDelete(`/v2/ClearPlotsFromCustomerFavourites/`, {
					customerEmail: state.user.email,
					PlotIds: state.favPlotsToRemove,
				});
				dispatch({ type: 'remotePlotRemoved' });
			} catch (error) {
				// console.log('There was a problem.');
			}
		}

		if (state.loggedIn && state.favPlotsToRemove.length > 0) {
			removeRemoteFavPlots();
		}
	}, [state.favRemoteUpdate]);

	//disable right click
	if (state.environment === 'production') {
		document.addEventListener('contextmenu', (event) => event.preventDefault());
	}

	//activate LogRocket
	useEffect(() => {
		const logRocketId = window['runConfig'].logRocketId;
		if (logRocketId && cookies.inMarketingSuite === 'true') {
			LogRocket.init(logRocketId);
		}
	}, []);

	//block text selection in marketing suite mode
	useEffect(() => {
		if (cookies.inMarketingSuite === 'true') {
			document.body.classList.add('noselect');
		} else {
			document.body.classList.remove('noselect');
		}
	}, []);

	//handle logged in state
	useEffect(() => {
		if (state.loggedIn) {
			localStorage.setItem('user', JSON.stringify(state.user));
		} else {
			localStorage.removeItem('user');
		}
	}, [state.loggedIn, state.user]);

	function Settings() {
		return (
			<CookiesProvider>
				{cookies.inMarketingSuite === 'true' && <IdleTimerContainer />}

				{cookies.haveLights === 'true' && cookies.lightServerAddress && (
					<LightServer lightServerAddress={cookies.lightServerAddress} />
				)}
			</CookiesProvider>
		);
	}

	if (!state.allLoaded) {
		return <Spinner height='100vh' />;
	} else {
		return (
			<>
				<Helmet>
					<title>{`${state.devName.name} - ${state.clientName.clientName}`}</title>
				</Helmet>
				<Theme />
				<BrowserRouter>
					<Header />
					{state.forceLogin ? <Login /> : null}
					{state.forceLogin ? null : <AppRoutes />}
					<CheckDevice />
					<Settings />
					{state.forceLogin ? null : <Footer />}
				</BrowserRouter>
			</>
		);
	}
};

export default App;
