import React from 'react';
import '../assets/styles/components/Badge.scss';

function Badge(props) {
	return (
		<span className='badge' style={props.style}>
			{props.text}
		</span>
	);
}

export default Badge;
