import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Context } from '../contexts/Store';

import Menu from './Menu';
import GlobalFilters from './GlobalFilters';
import GlobalFavourites from './GlobalFavourites';
import Disclaimer from './Disclaimer';

import '../assets/styles/components/Footer.scss';

function Footer() {
	const { state, dispatch } = useContext(Context);
	const location = useLocation();
	const navigate = useNavigate();
	const [displayFilter, setDisplayFilter] = useState(false);
	const logo = state.devStyle.images.additionalLogoUrl;

	useEffect(() => {
		setDisplayFilter(false);

		if (state.filterRoutes && !location.pathname.includes('/homes/')) {
			state.filterRoutes.every((thisRoute) => {
				const hasRoute = location.pathname.includes(thisRoute);
				if (hasRoute) {
					setDisplayFilter(true);
					return false;
				}
				return true;
			});
		}
	}, [location, state.filterRoutes]);

	// When 2 comparison plots selected, autoredirect to compare
	useEffect(() => {
		if (!location.pathname.includes('/compare') && state.comparePlots.length >= 2) {
			dispatch({ type: 'setIsCompareMode', data: false });
			dispatch({ type: 'resetComparePlots' });
		}
	}, [location]);

	// If navigating away from compare, reset the comparison data
	useEffect(() => {
		if (!location.pathname.includes('/compare') && state.comparePlots.length >= 2) {
			navigate('/compare');
		}
	}, [state.comparePlots]);

	return (
		<>
			{state.globalDisclaimer && <Disclaimer />}
			<div className='Footer'>
				{logo && !state.isMobileDevice && (
					<Link to='/'>
						<img src={logo} alt='' className='Footer__Logo' loading='lazy' />
					</Link>
				)}

				<div className='Footer__Drawer'>
					{displayFilter && <GlobalFilters />}
					<GlobalFavourites />
					<Menu />
				</div>
			</div>
		</>
	);
}

export default Footer;
