import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Context } from '../contexts/Store';

import '../assets/styles/components/Header.scss';

function Header() {
	const { state } = useContext(Context);
	const { hideHeader, isMobileDevice, clientName, devName } = state;

	return (
		<>
			{!hideHeader && (
				<header className='Header'>
					{!isMobileDevice && (
						<>
							<Link to='/' className='Header__Name'>
								{clientName.clientName}
							</Link>
							<p className='Header__Development'>{devName.name}</p>
						</>
					)}
				</header>
			)}
		</>
	);
}

export default Header;
