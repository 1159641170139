import React from 'react';

import '../assets/styles/components/ArrowIcon.scss';
import { ReactComponent as Icon } from '../assets/img/arrow-right.svg';

function ArrowIcon({ className = '' }) {
	return (
		<span className={`ArrowIcon ${className}`}>
			<Icon />
		</span>
	);
}

export default ArrowIcon;
