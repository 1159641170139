import { useContext, useEffect, useState } from 'react';

import { Context } from '../contexts/Store';
import { getPlotById, filteredCountByType } from '../helpers/utilities';

import PlotLink from './PlotLink';
import MiniType from './MiniType';

import '../assets/styles/components/PlotSelector.scss';
import { ReactComponent as IconHeartLight } from '../assets/img/heart-light.svg';
import { ReactComponent as IconPlus } from '../assets/img/plus.svg';

function PlotSelector() {
	const { state } = useContext(Context);
	const [filteredFavs, setFilteredFavs] = useState([]);

	useEffect(() => {
		if (state.favPlots) {
			const favs = state.favPlots.filter(
				(item) => !state.comparePlots.includes(item) && state.plots.includes(item)
			);
			setFilteredFavs(favs);
		}
	}, [state.favPlots, state.comparePlots]);

	return (
		<div className='PlotSelector'>
			<h2 className='PlotSelector__Heading'>
				<IconPlus />
				Select a home to compare
			</h2>

			{filteredFavs.length > 0 && (
				<h3 className='PlotSelector__Subheading'>
					<IconHeartLight />
					Your Favourites
				</h3>
			)}

			<div className='PlotSelector__Plots'>
				{filteredFavs.map((item, key) => {
					return <PlotLink plot={getPlotById(state.plots, item)} key={key} />;
				})}
			</div>

			{state.types &&
				state.types.map((type, i) => {
					if (filteredCountByType(state.filteredHomes, type) > 0) {
						return <MiniType type={type} key={i} />;
					}
				})}
		</div>
	);
}

export default PlotSelector;
