import React, { useContext } from 'react';

import { Context } from '../contexts/Store';
import { resetFilter } from '../contexts/Filters';

import '../assets/styles/components/ActiveFilters.scss';
import { ReactComponent as IconTick } from '../assets/img/tick.svg';
import { ReactComponent as IconFilters } from '../assets/img/filter.svg';

function ActiveFilters() {
	const { state, dispatch } = useContext(Context);

	const showHomes = `${state.filteredHomes.length} ${
		state.filteredHomes.length == 1 ? ' home' : ' homes'
	}`;

	return (
		<section className='ActiveFilters'>
			<ul className='ActiveFilters__Inner'>
				<li className='ActiveFilters__Item ActiveFilters__Item--count'>
					<span className='ActiveFilters__Icon'>
						<IconFilters />
					</span>
					{showHomes}
				</li>
				{state.filters.map((item, filterIndex) => {
					if (item.active && item.name !== 'plotStatus') {
						return (
							<li
								className='ActiveFilters__Item ActiveFilters__Item--filter'
								key={item.id}
								onClick={() => resetFilter(state, dispatch, filterIndex)}
							>
								<IconTick />
								{item.displayName}
							</li>
						);
					}
				})}
			</ul>
		</section>
	);
}

export default ActiveFilters;
