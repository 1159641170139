export function sortHomes(homes, sortKey, sortOrder) {
	const sortedHomes = [...homes].sort((a, b) => {
		if (a[sortKey] === null) return 1;
		if (b[sortKey] === null) return -1;
		if (a[sortKey] === null && b[sortKey] === null) return 0;
		return (
			a[sortKey].toString().localeCompare(b[sortKey].toString(), 'en', {
				numeric: true,
			}) * (sortOrder === 'asc' ? 1 : -1)
		);
	});

	return sortedHomes;
}
