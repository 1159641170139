import React, { useContext, useEffect, useState } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import { Context } from '../contexts/Store';
import '../assets/styles/components/Breadcrumbs.scss';

function Breadcrumbs() {
	const { state } = useContext(Context);
	const [masterplanRoutes, setMasterplanRoutes] = useState([
		{ path: '/site', breadcrumb: 'Full Site' },
	]);

	useEffect(() => {
		getName(state.plotContainers);
	}, []);

	function getName(obj) {
		for (const plotContainerData of obj) {
			const data = {
				path: `/site/${plotContainerData.id}`,
				breadcrumb: plotContainerData.name,
			};

			setMasterplanRoutes((prevArray) => [...prevArray, data]);

			if (Object.prototype.hasOwnProperty.call(plotContainerData, 'plotContainers')) {
				getName(plotContainerData.plotContainers);
			}
		}
	}

	const breadcrumbs = useBreadcrumbs(masterplanRoutes);

	return (
		<nav className='Breadcrumbs'>
			<ul className='Breadcrumbs__List'>
				{breadcrumbs.map(({ match, breadcrumb }) => {
					if (breadcrumb.props.children !== 'Home') {
						return (
							<span className='Breadcrumbs__Item Breadcrumbs__Item--Link' key={match.pathname}>
								<NavLink className='Breadcrumbs__Link' to={match.pathname}>
									{breadcrumb}
								</NavLink>
							</span>
						);
					}
					return;
				})}
			</ul>
		</nav>
	);
}

export default Breadcrumbs;
