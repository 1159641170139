import Dropdown from './Dropdown';
import SortButton from './SortButton';

function SortByDropdown({ placement }) {
	return (
		<Dropdown label='Sort by' icon='sort' placement={placement}>
			<SortButton sortKey='plotNumber'>Plot</SortButton>
			<SortButton sortKey='price'>Price</SortButton>
			<SortButton sortKey='description'>Bedrooms</SortButton>
		</Dropdown>
	);
}

export default SortByDropdown;
