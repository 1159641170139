import React, { useContext, useRef, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { usePrevious } from '../helpers/utilities';

import { Context } from '../contexts/Store';

import PlotLink from './PlotLink';
import Button from './Button';
import ToggleButton from './ToggleButton';

import '../assets/styles/components/GlobalFavourites.scss';
import useDropdown from '../hooks/useDropdown';

function GlobalFavourites() {
	const { state } = useContext(Context);
	const [showFavourites, setShowFavourites] = useState(false);
	const buttonRef = useRef(null);
	const containerRef = useRef(null);

	const animateStyle = { originX: state.isMobileDevice ? 0 : 1, originY: 1 };

	/**
	 * Expand the favourites tray when favouriting a plot for the first time
	 * usePrevious to check the count is going from 0 -> 1, and not 2 -> 1
	 */
	const previousCount = usePrevious(state.favPlots ? state.favPlots.length : 0);
	useEffect(() => {
		if (state.favPlots) {
			if (state.favPlots.length === 1 && previousCount === 0) {
				setShowFavourites(true);
			}
		}
	}, [state.favPlots]);

	useDropdown(buttonRef, containerRef, setShowFavourites);

	return (
		<>
			{state.favPlots && state.favPlots.length > 0 && (
				<div className='GlobalFavourites'>
					<AnimatePresence>
						{showFavourites && (
							<motion.div
								className='GlobalFavourites__Favourites'
								style={animateStyle}
								initial={{ opacity: 0, y: 10, scale: 0.5 }}
								animate={{ opacity: 1, y: -5, scale: 1 }}
								exit={{ opacity: 0, y: 10, scale: 0.5 }}
								transition={{
									duration: 0.25,
									ease: [0.6, 0.01, -0.05, 0.95],
								}}
								ref={containerRef}
							>
								<div className='GlobalFavourites__List'>
									{state.favPlots.map((id, i) => {
										const plot = state.plots.find((plot) => id === plot.id);
										if (plot) {
											return <PlotLink plot={plot} key={i} />;
										}
										return null;
									})}
								</div>
								<div className='GlobalFavourites__Actions'>
									<Button to='favourites' size='xs' onClick={() => setShowFavourites(false)}>
										View all
									</Button>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
					<ToggleButton
						icon={showFavourites ? 'heart' : 'heart-light'}
						onClick={() => setShowFavourites(!showFavourites)}
						count={state.favPlots.length}
						ref={buttonRef}
					/>
				</div>
			)}
		</>
	);
}

export default GlobalFavourites;
