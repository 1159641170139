import { useContext } from 'react';
import { Context } from '../contexts/Store';
import { dynamicSort, findFloorplans } from '../helpers/utilities';
import { useLocation } from 'react-router-dom';

const FP_PREFIX = 'FP_';

const usePlotCardImages = (item) => {
	const { state } = useContext(Context);

	const { plotType, plotSpecificAssets } = item;
	const location = useLocation();
	const isExteriorTour = location.pathname.includes('exterior-tour');

	const sortedAssets = plotType.assets.slice().sort(dynamicSort('order'));

	const nonFloorplanImages = sortedAssets.filter((asset) => !asset.name.includes(FP_PREFIX));

	const plotTypeFloorplans = findFloorplans(plotType.assets);

	const specificAssetsFloorplans =
		plotSpecificAssets && plotSpecificAssets.length > 0 ? findFloorplans(plotSpecificAssets) : [];

	const floorplans = [...plotTypeFloorplans, ...specificAssetsFloorplans];

	const imagesOrder = state.floorplansFirst
		? [...floorplans, ...nonFloorplanImages]
		: [...nonFloorplanImages, ...floorplans];

	const sliderImages = isExteriorTour ? floorplans : imagesOrder;

	return { sliderImages, floorplans };
};

export { usePlotCardImages };
