import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';

import Icon from './Icon';

import '../assets/styles/components/Dropdown.scss';

function Dropdown({ label, icon, placement = 'below', ...props }) {
	const [active, setActive] = useState(false);
	const id = label.toLowerCase().replace(' ', '-');

	/*
	 * Handle click outside
	 */
	const wrapperRef = useRef(null);
	const containerRef = useRef(null);
	const buttonRef = useRef(null);
	const handleClickOutside = (e) => {
		if (
			containerRef.current &&
			!containerRef.current.contains(e.target) &&
			buttonRef.current &&
			!buttonRef.current.contains(e.target)
		) {
			setActive(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside, true);
		window.addEventListener('blur', {}, true);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, true);
			window.removeEventListener('blur', {}, true);
		};
	});

	return (
		<AnimateSharedLayout>
			<div className={`Dropdown Dropdown--${placement}`} ref={wrapperRef}>
				<button
					className='Dropdown__Toggle'
					aria-controls={`#{id}`}
					ref={buttonRef}
					onClick={() => setActive(!active)}
				>
					{icon && <Icon icon={icon} />}
					{label}
				</button>
				<AnimatePresence>
					{active && (
						<motion.div
							id={id}
							className='Dropdown__Content'
							style={{ originX: 1, originY: placement === 'above' ? 1 : 0 }}
							initial={{ opacity: 0, y: 20, scale: 0.5 }}
							animate={{ opacity: 1, y: placement === 'above' ? -5 : 5, scale: 1 }}
							exit={{ opacity: 0, y: placement === 'above' ? -5 : 5, scale: 0.5 }}
							transition={{
								duration: 0.25,
								ease: [0.6, 0.01, -0.05, 0.95],
							}}
							ref={containerRef}
						>
							{props.children}
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</AnimateSharedLayout>
	);
}

export default Dropdown;
