import React, { useState } from 'react';
import ActionButton from './ActionButton';
import Modal from './Modal';

function PlotSpecificationButton({ link }) {
	const [isShown, setIsShown] = useState(false);

	if (!link) return null;

	const url = new URL(link);

	return (
		<>
			{isShown && (
				<Modal onClose={() => setIsShown(false)}>
					<iframe src={url}></iframe>
				</Modal>
			)}

			<ActionButton variant='primary' icon='specification' onClick={() => setIsShown(!isShown)}>
				Specification
			</ActionButton>
		</>
	);
}

export default PlotSpecificationButton;
