import { React, useContext, Suspense, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Context } from '../contexts/Store';
import { SuspenseImg } from '../components/SuspenseImage';
import Modal from '../components/Modal';
import '../assets/styles/pages/Gallery.scss';

function InteriorTours() {
	const { state } = useContext(Context);
	const { interiorTourData } = state;
	const [isShown, setIsShown] = useState(false);
	const [url, setUrl] = useState('');

	function LoadingImages() {
		return (
			<div className='Gallery__Grid'>
				{interiorTourData && interiorTourData[0].assets
					? interiorTourData[0].assets.map((asset, assetsIndex) => {
							return (
								<div
									className='Gallery__Item Gallery__Item--skeleton'
									key={`${0}_${assetsIndex}`}
								></div>
							);
					  })
					: null}
			</div>
		);
	}

	const itemClicked = (thisUrl) => {
		setUrl(thisUrl);
		setIsShown(true);
	};

	function ShowImages() {
		return (
			<div>
				{isShown && (
					<Modal onClose={() => setIsShown(false)} isLarge={true}>
						<iframe src={url}></iframe>
					</Modal>
				)}

				<div className='Gallery__Grid'>
					{interiorTourData && interiorTourData[0] && interiorTourData[0].assets
						? interiorTourData[0].assets.map((asset) => {
								if (asset.link) {
									if (asset.fileUrl.endsWith('jpg') || asset.fileUrl.endsWith('jpeg')) {
										return (
											<div
												className='Gallery__Item int-tour'
												onClick={() => itemClicked(asset.link)}
											>
												<div className='Gallery__Item__Caption'>{asset.name}</div>
												<SuspenseImg alt={asset.name} src={asset.fileUrl} />
											</div>
										);
									} else {
										return null;
									}
								} else {
									return null;
								}
						  })
						: null}
				</div>
			</div>
		);
	}

	return (
		<div className='Gallery'>
			<Helmet>
				<title>{`Interior Tours - ${state.devName.name}`}</title>
			</Helmet>

			<Suspense fallback={<LoadingImages />}>{interiorTourData ? <ShowImages /> : null}</Suspense>
		</div>
	);
}

export default InteriorTours;
